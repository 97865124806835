import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { SetterOrUpdater } from "recoil";

interface ActionToolbarPopupProps {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  index: number;
  name: string;
  initialValue: boolean;
  setToDefault: React.Dispatch<React.SetStateAction<boolean>>;
  toDefault: boolean;
  filterArray: string[];
  setFilterArray: SetterOrUpdater<string[]>;
  className?: string;
}

function ActionToolbarFilterCheckbox({
  customRef,
  index,
  filterArray,
  initialValue = false,
  setFilterArray,
  setToDefault,
  toDefault,
  name,
  className,
}: ActionToolbarPopupProps) {
  const [isChecked, setChecked] = useState(initialValue);
  useEffect(() => {
    if (isChecked) {
      setFilterArray((prevState) => [...[...prevState, name]]);
    }
  }, [setFilterArray, index, isChecked, name]);
  useEffect(() => {
    if (toDefault) {
      setChecked(false);
    }
  }, [toDefault]);

  return (
    <div
      onClick={() => {
        setToDefault(false);
        setChecked((val) => !val);
        if (isChecked) {
          removeFilterItem(filterArray, name, setFilterArray);
        }
      }}
      ref={customRef}
      className={classnames(className, "  ")}
    >
      <div className="checkbox-container block relative pl-9 mb-3 cursor-pointer select-none">
        {name}
        <input
          checked={isChecked}
          className="absolute opacity-0 h-0 w-0"
          type="checkbox"
        />
        <span className="checkmark absolute top-0 left-0 h-6 w-6 bg-checkboxBg" />
      </div>
    </div>
  );
}
function removeFilterItem(
  filterArray: string[],
  name: string,
  setFilterArray: React.Dispatch<React.SetStateAction<string[]>>
) {
  const newList = filterArray.filter((item) => item !== name);
  setFilterArray(newList);
}
export default ActionToolbarFilterCheckbox;
