import logoRegular from "assets/images/logo.png";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Help from "@material-ui/icons/Help";

function NotFound() {
  return (
    <div className="flex h-full w-full items-center justify-center flex-col p-8 mb-16">
      <div className="mb-10">
        <img
          alt="logo"
          className="min-w-[150px] w-[150px]"
          src={logoRegular}
        ></img>
      </div>
      <div className="text-center mb-14">
        <p className="text-base font-normal text-lightGreen mb-4">404 Fehler</p>
        <h1 className="text-4xl font-bold text-darkBlue mb-4">
          Diese Seite existiert nicht
        </h1>
        <p className="text-lg font-normal text-darkBlue05">
          Die Seite, die du aufrufen wolltest, konnte nicht gefunden werden.
        </p>
      </div>
      <div className="max-w-[700px] w-full">
        <div className="w-full text-left border-b">
          <p className="uppercase text-darkBlue pl-4 pb-4 font-bold">
            Häufig gesucht
          </p>
        </div>
        <div className="grid grid-cols-1 gap-0">
          <a
            href="https://intercom.help/membia/de"
            target="_blank"
            rel="noreferrer"
          >
            <div className="group pl-4 py-6 border-b flex items-center hover:bg-iconBg hover:cursor-pointer transition-all duration-300 ease-in-out ">
              <div className="mr-6 rounded-md w-[50px] h-[50px] bg-darkBlue flex items-center justify-center">
                <Help style={{ fill: "#fff" }} />
              </div>
              <div className="">
                <p className="text-base font-bold text-darkBlue">Support</p>
                <p className="text-sm font-normal text-darkBlue05">
                  Lasse dir von unserem Support helfen.
                </p>
              </div>
              <div className="w-[50px] h-[50px] justify-end ml-auto pr-4 flex items-center opacity-50 group-hover:opacity-100">
                <ChevronRight style={{ fill: "#2C3F5A" }} />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
