import { atom, useSetRecoilState, useRecoilValue } from "recoil";

import { STORE } from "const";
import { ILocation } from "types";

export const locationState = atom<ILocation>({
  key: STORE.LOCATION,
  default: { currentLocation: "", previousLocation: "" },
});

export function useSetLocationDataHandler() {
  return useSetRecoilState(locationState);
}

export function useGetLocationData() {
  return useRecoilValue(locationState);
}
