export enum APP_ROUTES {
  HOME = "/",
  CARD_BUILDER = "/card-picker",
  SEQUENCE_BUILDER = "/sequence-builder",
  NOTFOUND = "/404",
  MOBILE_SPLASH = "/mobile-splash",
}

export interface IRoutePathname {
  name: string;
  path: string;
  subtitle?: string | undefined;
}

export interface IRouteSubtitle {
  name: string;
  path: string;
  subtitle: string | undefined;
}
export const ROUTE_PATHNAMES: IRoutePathname[] = [
  { name: "Dashboard", path: "dashboard", subtitle: undefined },
];

export const PUBLIC_ROUTE_SUBTITLES: IRouteSubtitle[] = [
  { name: "Login", path: "login", subtitle: undefined },
];

export default APP_ROUTES;
