import ReactDOM from "react-dom";
import { ReactNode } from "react";
import classnames from "classnames";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  headerChildren?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  portalNode?: HTMLElement;
  className?: string;
  isShowing?: boolean;
  showTitle?: boolean;
  isSmallScreen: boolean;
  showHeader?: boolean;
  onHide?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  classNames?: {
    mainContainer?: string;
    subMainContainer?: string;

    titleContainer?: String;
    title?: string;
    subTitle?: string;

    modalHeader?: string;
    backgroundImage?: string;
    closeButtonContainer?: string;
    closeButton?: string;
    borderLine?: string;
    childrenContainer?: string;
    searchChildrenContainer?: string;
    headerChildrenStyle?: string;
  };
  customMaxWidth?: boolean;
}
function TutorialNotification({
  isShowing = false,
  showHeader = true,

  onHide,
  children,
  className,
  subTitle,
  showTitle = true,
  classNames,
  isSmallScreen,
  headerChildren,
  portalNode = document.body,
  title = "Title",
  customMaxWidth,
}: IProps) {
  console.log("DOCUMENT BODY", portalNode);

  return (
    <>
      {isShowing &&
        ReactDOM.createPortal(
          <div
            className={classnames(
              "z-[100] shadow-welcomePopup  right-0 mmxs:right-8  w-full   max-w-[395px]  2xl:max-w-[500px] fixed  rounded-[10px]  font-poppins  outline-none flex justify-center items-center overflow-y-hidden",
              {
                " bottom-0 ": isSmallScreen,
                " bottom-0 mmxs:bottom-[10.5rem]   md:bottom-[7rem]":
                  !isSmallScreen,
              },
              className
            )}
            tabIndex={-1}
            role="dialog"
          >
            <div
              className={classnames(
                classNames?.mainContainer,
                "overflow-y-auto h-full shadow-welcomePopup relative  w-full flex  items-start xs:items-center xs:justify-center"
              )}
            >
              <div
                className={classnames(
                  classNames?.subMainContainer,
                  "flex flex-col w-full max-w-[500px]   bg-white opacity-100 z-40 rounded-[10px] overflow-hidden "
                )}
              >
                {showHeader &&
                  (headerChildren ? (
                    <div
                      className={classnames(classNames?.headerChildrenStyle)}
                    >
                      {headerChildren}
                    </div>
                  ) : (
                    <div className="flex flex-col pt-8 pl-8 pr-8  w-full">
                      <div
                        className={classnames(
                          "flex flex-row justify-between w-full relative pb-4",
                          classNames?.modalHeader
                        )}
                      >
                        {showTitle && (
                          <div
                            className={classnames(
                              classNames?.titleContainer,
                              " flex  flex-col items-start justify-start "
                            )}
                          >
                            <p
                              className={classnames(
                                classNames?.title,
                                "   text-black font-black text-base 2xl:text-xl"
                              )}
                            >
                              {title}
                            </p>
                            {subTitle && (
                              <p
                                className={classnames(
                                  classNames?.subTitle,
                                  " font-normal text-black mt-2 text-sm 2xl:text-base "
                                )}
                              >
                                {subTitle}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={classnames(
                          classNames?.closeButtonContainer,
                          " absolute top-5 right-5 justify-center text-alert opacity-40 hover:opacity-100 items-start"
                        )}
                      >
                        {onHide && (
                          <FontAwesomeIcon
                            onClick={onHide}
                            className=" fa-lg 2xl:fa-2x  cursor-pointer"
                            icon={faTimesCircle}
                          />
                        )}
                      </div>
                    </div>
                  ))}

                <div className={classnames(classNames?.childrenContainer)}>
                  {children}
                </div>
              </div>
            </div>
          </div>,
          portalNode
        )}
    </>
  );
}

export default TutorialNotification;
