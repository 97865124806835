export enum ASANA_FILTER {
  ARM_BALANCE = "Arm Balance",
  BACKBEND = "Backbend",
  BALANCING = "Balancing",
  BINDING_POSES = "Binding Poses",
  CHEST_OPENING = "Chest-opening",
  CORE = "Core",
  FORWARD_FOLD = "Forward Fold",
  HAMSTRING_STRETCH = "Hamstring Stretch",
  HIP_OPENING = "Hip-opening",
  INVERSION = "Inversion",
  KNEELING = "Kneeling",
  MUDRA = "Mudra",
  PRANAYAMA = "Pranayama",
  PRONE = "Prone",
  RESTORATIVE = "Restorative",
  SEATED = "Seated",
  SHOULDER_OPENING = "Shoulder-opening",
  SIDE_BENDING = "Side-bending",
  STANDING = "Standing",
  SUPINE = "Supine",
  TWIST = "Twist",
}

export default ASANA_FILTER;
