import classnames from "classnames";

import React from "react";
import { twMerge } from "tailwind-merge";

interface BurgerProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  customRef?: React.MutableRefObject<HTMLButtonElement | null>;
  classNames?: {
    className?: string;
    buttonText?: string;
  };
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Burger({
  customRef,
  classNames,
  openMenu,
  setOpenMenu,
  ...rest
}: BurgerProps) {
  return (
    <button
      ref={customRef}
      {...rest}
      disabled={rest.disabled}
      onClick={() => {
        if (!openMenu) {
          document.body.classList.add("lock-screen");
          document
            .getElementById("main-container")
            ?.classList.add("lock-screen");
          document.getElementById("sub-root")?.classList.add("lock-screen");
          document.getElementById("root")?.classList.add("lock-screen");
          document.getElementById("html")?.classList.add("lock-screen");
        } else {
          document.body.classList.remove("lock-screen");
          document
            .getElementById("main-container")
            ?.classList.remove("lock-screen");
          document.getElementById("sub-root")?.classList.remove("lock-screen");
          document.getElementById("root")?.classList.remove("lock-screen");
          document.getElementById("html")?.classList.remove("lock-screen");
        }
        setOpenMenu(!openMenu);
      }}
      className={classnames(
        "  burger relative flex items-center justify-center",
        {
          "pointer-events-none opacity-50 border-transparent ": rest.disabled,
          "  ": !rest.disabled,
        },
        rest.className
      )}
    >
      <div
        className={twMerge(
          classnames("top-bar bg-nero z-10 absolute top-0 w-8 ", {
            "rotate-[45deg] w-[31px]": openMenu,
            " rotate-0 ": !openMenu,
          })
        )}
      />
      <div
        className={classnames("central-bar bg-nero absolute w-8 ", {
          " translate-x-[20px] opacity-0 w-[31px]": openMenu,
          " translate-x-0 ": !openMenu,
        })}
      />
      <div
        className={twMerge(
          classnames("top-bar bg-nero absolute bottom-0 w-8", {
            " rotate-[-45deg] w-[31px]  ": openMenu,
            // " rotate-0 bg-white": !openMenu,
          })
        )}
      />
    </button>
  );
}

export default Burger;
