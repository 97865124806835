import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-duotone-svg-icons";
import React, { useEffect, useState } from "react";
import { ISearchString } from "types";
import { twMerge } from "tailwind-merge";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  customRef?: React.MutableRefObject<HTMLInputElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };

  setValue?: React.Dispatch<React.SetStateAction<string>>;
  search?: ISearchString;
  inputType?: string;
  placeholder?: string;
  ownValue?: string;
}

export function Input({
  customRef,
  classNames,
  ownValue,
  inputType,
  placeholder,
  search,

  setValue,
  ...rest
}: InputProps) {
  const [blockHovered, setBlockHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (inputType === "search") {
      if (!search?.searchString || search?.searchString.length < 1) {
        if (setValue) {
          setValue("");
        }
      }
    }
  }, [inputType, search, setValue]);

  return (
    <div
      onMouseOver={() => {
        setBlockHovered(true);
      }}
      onMouseOut={() => {
        setBlockHovered(false);
      }}
      className={classnames(
        // "sm:mr-4 border-gainsboro hover:border-gold focus:border-gold"
        "flex flex-row justify-between w-full  rounded-[5px] max-h-14 bg-white   min-w-[14rem] border border-gainsboro hover:border-gold focus:border-gold",
        classNames?.container
      )}
    >
      <input
        {...rest}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        className={twMerge(
          classnames(
            "pl-[15px] pt-[13px] pb-[10px] text-base h-full w-full min-w-[14rem]  text-black leading-[1rem] font-poppins outline-none border-none bg-transparent overflow-hidden  align-middle ",
            { "search-input-active": Number(ownValue?.length) > 0 },
            classNames?.input
          )
        )}
        placeholder={placeholder}
        type={inputType}
      />
      {inputType !== "search" && (
        <div className="flex justify-center pl-[12px] pr-[12px]">
          <FontAwesomeIcon
            className={classnames("fa-lg h-full   text-darkBlue  z-10", {
              " text-opacity-100 ": blockHovered || isFocused,
              " text-opacity-40 ": !blockHovered && !isFocused,
            })}
            icon={faPen}
          />
        </div>
      )}
    </div>
  );
}

export default Input;
