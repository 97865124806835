import classnames from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

interface ActionToolbarPopupProps {
  customRef: React.MutableRefObject<HTMLDivElement | null>;
  popupHeader?: string;
  customStyles?: boolean;
  actionsPopup?: boolean;
  popupContent: JSX.Element;
  showPopup: boolean;
  className?: string;
  position?: "right" | "center" | "left";
  popupType?: "regular" | "share";
  popupDirection?: "horizontal" | "vertical";
  isOnTheToolbar?: boolean;
}

function ActionToolbarPopup({
  customRef,
  popupHeader,
  position,
  popupContent,
  popupType,
  showPopup,
  isOnTheToolbar = true,
  popupDirection = "vertical",
  className,
}: ActionToolbarPopupProps) {
  return popupType === "regular" ? (
    <div
      ref={customRef}
      className={twMerge(
        classnames(
          " absolute z-50 w-max-content  whitespace-nowrap  bg-white border rounded-[10px]    shadow-popup ",
          {
            "mt-[64px]": isOnTheToolbar,
            "mt-[15px]": !isOnTheToolbar,
          },
          {
            "min-w-[217px]": popupDirection === "vertical",
            "min-w-[140px]": popupDirection === "horizontal",
            hidden: !showPopup,
            "right-0": position === "right",
            "left-0": position === "left",
            "left-[50%] translate-x-[-50%]": position === "center",
          },
          className
        )
      )}
    >
      <div className="flex flex-col">
        <div
          hidden={!popupHeader}
          className={classnames(
            "h-[50px] border-b flex justify-start items-center p-4 mt-[5px] font-bold text-base pointer-events-none text-darkBlue",
            {
              hidden: !popupHeader,
            }
          )}
        >
          <h1>{popupHeader}</h1>
        </div>
        <div className="">{popupContent}</div>
      </div>
    </div>
  ) : popupType === "share" ? (
    <div
      ref={customRef}
      className={classnames(
        className,
        " absolute z-50 w-max-content  whitespace-nowrap  bg-white border rounded-[10px] mt-[15px] shadow-popup ",
        {
          "min-w-[217px]": popupDirection === "vertical",
          "min-w-[140px]": popupDirection === "horizontal",
          hidden: !showPopup,
          "right-0": position === "right",
          "left-0": position === "left",
          "left-[50%] translate-x-[-50%]": position === "center",
        }
      )}
    >
      <div className="flex flex-col">
        <div
          hidden={!popupHeader}
          className={classnames(
            "h-[50px] border-b flex justify-start items-center p-4 mt-[5px] font-bold text-base pointer-events-none text-darkBlue",
            {
              hidden: !popupHeader,
            }
          )}
        >
          <h1>{popupHeader}</h1>
        </div>
        <div className="">{popupContent}</div>
      </div>
    </div>
  ) : null;
}

export default ActionToolbarPopup;
