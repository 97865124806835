import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-duotone-svg-icons";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { GridItem } from "react-grid-dnd";
import { APP_ROUTES } from "const";

interface AsanaCardProps extends React.HTMLAttributes<HTMLDivElement> {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };
  boxesPerRow?: number;
  componentKey?: string;
  inputType?: string;
  placeholder?: string;
}

export function AsanaCard({
  customRef,
  classNames,
  componentKey,
  inputType,
  placeholder,
  boxesPerRow = 5,
  ...rest
}: AsanaCardProps) {
  const [, setCardHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <GridItem
      onTouchMove={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      translate="no"
      draggable={false}
      className="disabled"
      style={{
        padding: "20px",
        paddingLeft: boxesPerRow < 3 ? "10px" : "20px",
        paddingRight: boxesPerRow < 3 ? "10px" : "20px",
        paddingBottom: "40px",
      }}
      {...rest}
      key={componentKey}
    >
      <div
        onMouseOver={() => {
          setCardHovered(true);
        }}
        onMouseOut={() => {
          setCardHovered(false);
        }}
        onClick={() => {
          navigate(APP_ROUTES.CARD_BUILDER);
        }}
        className={classnames(
          "relative flex flex-col hover:bg-addCardHoverBg justify-center z-20  items-center h-full min-h-[13.125rem] md:min-h-[18.25rem] rounded-[20px] overflow-hidden  border border-darkBlue01 hover:border-[#F6F1EA] cursor-pointer",
          classNames?.container
        )}
      >
        <div>
          <FontAwesomeIcon
            className="fa-3x text-[#DDBC93]   "
            icon={faPlusCircle}
          />
        </div>
        <div className="mt-[27px]">
          <p className="text-md font-bold ">Add pose</p>
        </div>
      </div>
    </GridItem>
  );
}

export default AsanaCard;
