import { default as axiosService } from "axios";

export const axios = axiosService.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
//add some logic if needed

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //add some logic if needed
    return Promise.reject(error);
  }
);

export default axios;
