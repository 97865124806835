import classnames from "classnames";
import { Loader } from "components";

import React, { useState } from "react";

interface RoundButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  customRef?: React.MutableRefObject<HTMLButtonElement | null>;
  applyActionPanelStyle?: boolean;
  formStatus?: any;
  classNames?: {
    className?: string;
    mainContainer: string;
    buttonText?: string;
  };
  tooltip?: JSX.Element;
  tooltipText?: string;
  isOverrideType?: boolean;
  option?: string;
  buttonType?: string;
  disable?: boolean;
  onClick?: () => void;
}

export function RoundButton({
  option,
  buttonType,
  customRef,
  tooltipText,
  formStatus,
  classNames,
  tooltip,
  disable = false,
  onClick,
  ...rest
}: RoundButtonProps) {
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <div
      className={classnames("relative", classNames?.mainContainer)}
      onMouseOver={() => {
        if (tooltipText) {
          setShowToolTip(true);
        }
      }}
      onMouseOut={() => {
        setShowToolTip(false);
      }}
    >
      <button
        ref={customRef}
        {...rest}
        disabled={disable}
        onClick={onClick}
        className={classnames(
          "   p-2 px-3 minHeaderSize:p-3  font-black transition-all transform duration-[250ms]  ease-linear rounded-[50px]  bg-roundedButtonHeaderBg border border-roundedButtonHeaderBorder  flex justify-center items-center ",
          {
            "pointer-events-none opacity-50 border-transparent ": disable,
            "  ": !disable,
          },
          rest.className
        )}
      >
        <div
          className={classnames("", {
            hidden: !rest.children,
          })}
        >
          {rest.children ? rest.children : null}
        </div>

        {!formStatus?.loading === true && option && (
          <p
            className={classnames(
              classNames?.buttonText,
              " mt-[2px] text-darkBlue  text-base whitespace-nowrap",
              {
                "text-deny": buttonType === "deny",
                "text-darkBlue": buttonType === "approve",
              }
            )}
          >
            {option}
          </p>
        )}
        {formStatus?.loading === true && (
          <div className="flex justify-center">
            <Loader />
          </div>
        )}
      </button>
      {tooltipText && (
        <span
          className={classnames(
            `absolute bg-tooltipBg text-darkBlue  max-w-[220px] left-[50%] translate-x-[-50%] break-words text-center rounded-[6px] px-2 py-1.5 z-[60] top-[115%]   font-bold after:tooltiptoparrow`,
            {
              invisible: !showToolTip,
              visible: showToolTip,
              "min-w-min": tooltipText.length < 10,
              "min-w-[120px]":
                tooltipText.length > 10 && tooltipText.length < 15,
              "min-w-[160px]":
                tooltipText.length > 15 && tooltipText.length < 30,
              "min-w-[200px]":
                tooltipText.length > 30 && tooltipText.length < 50,
              "min-w-[220px]": tooltipText.length > 50,
            }
          )}
        >
          <p className="text-sm ">{tooltipText}</p>
        </span>
      )}
    </div>
  );
}

export default RoundButton;
