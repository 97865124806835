import { ActionToolbar, AsanaCard, AsanaCardAddNew } from "components";

import { useEffect, useLayoutEffect, useState } from "react";
import {
  useGetLocationData,
  useGetSequenceData,
  useSequenceDataHandler,
} from "store";

import { IPose, ISequence } from "types";
import { GridContextProvider, GridDropZone, swap } from "react-grid-dnd";
import { useLocation } from "react-router";
import { useGoToBottom } from "hooks";
import { APP_ROUTES } from "const";

function duplicateCard(
  sourceCard: IPose,
  sourceCardPosition: number,
  targetArray: IPose[],
  addToSequence: (
    valOrUpdater: ISequence | ((currVal: ISequence) => ISequence)
  ) => void
) {
  const tempArray = [...targetArray];
  tempArray.splice(sourceCardPosition + 1, 0, sourceCard);
  addToSequence((prevValue) => ({
    ...prevValue,
    sequence: tempArray,
  }));
}

function removeCard(
  sourceCardPosition: number,
  targetArray: IPose[],
  addToSequence: (
    valOrUpdater: ISequence | ((currVal: ISequence) => ISequence)
  ) => void
) {
  const tempArray = [...targetArray];
  tempArray.splice(sourceCardPosition, 1);
  addToSequence((prevValue) => ({
    ...prevValue,
    sequence: tempArray,
  }));
}

function SequenceBuilder() {
  const getSequenceData = useGetSequenceData();
  const locationData = useGetLocationData();
  const location = useLocation();
  const goBottom = useGoToBottom("footer");
  useEffect(() => {
    if (locationData.previousLocation === APP_ROUTES.CARD_BUILDER) {
      goBottom();
    }
  }, [goBottom, locationData.previousLocation, location]);

  const [initialize, setInitialize] = useState(
    getSequenceData?.sequence?.length < 1
  );

  const addToSequence = useSequenceDataHandler();
  useEffect(() => {
    if (getSequenceData?.sequence?.length < 1 && initialize) {
      setInitialize(false);
    }
  }, [addToSequence, getSequenceData.sequence, initialize, setInitialize]);

  const [boxesPerRow, setBoxesPerRow] = useState(1);
  const [rowHeight, setRowHeight] = useState(360);
  const [, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    function updateSize() {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (viewportWidth > 1280) {
      setBoxesPerRow(5);
      setRowHeight(360);
    }
    if (viewportWidth > 1023 && viewportWidth < 1280) {
      setBoxesPerRow(4);
      setRowHeight(360);
    }
    if (viewportWidth > 767 && viewportWidth < 1024) {
      setBoxesPerRow(3);
      setRowHeight(360);
    }
    if (viewportWidth > 450 && viewportWidth < 768) {
      setBoxesPerRow(2);
      setRowHeight(320);
    }
    if (viewportWidth < 450) {
      setBoxesPerRow(2);
      setRowHeight(260);
    }
  }, [viewportWidth]);

  function onChange(
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) {
    const nextState = swap(getSequenceData.sequence, sourceIndex, targetIndex);
    addToSequence((prevValue) => ({
      ...prevValue,
      sequence: nextState,
    }));
  }
  return (
    <div className="flex bg-white  flex-col sm justify-center w-full h-full pb-4">
      <ActionToolbar toolbarType="builder" />

      <div className=" h-full w-full pb-[4.5rem] pt-[2.5rem] px-2 ">
        {boxesPerRow && rowHeight && (
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={boxesPerRow}
              rowHeight={rowHeight}
              style={{
                height: `${
                  Math.ceil(
                    (getSequenceData?.sequence?.length + 1) / boxesPerRow
                  ) * rowHeight
                }px`,
              }}
            >
              {getSequenceData.sequence &&
                getSequenceData.sequence.map((card, i) => {
                  if (card)
                    return (
                      <AsanaCard
                        boxesPerRow={boxesPerRow}
                        isDraggable
                        removeCard={() => {
                          removeCard(
                            i,
                            getSequenceData.sequence,
                            addToSequence
                          );
                        }}
                        duplicateCard={() => {
                          duplicateCard(
                            card,
                            i,
                            getSequenceData.sequence,
                            addToSequence
                          );
                        }}
                        isPicker={false}
                        index={i}
                        cardData={card}
                        key={`${card._id}${i}`}
                      />
                    );
                  return null;
                })}
              <AsanaCardAddNew boxesPerRow={boxesPerRow} key={"addPose"} />
            </GridDropZone>
          </GridContextProvider>
        )}
      </div>
    </div>
  );
}

export default SequenceBuilder;
