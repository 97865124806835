import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons";
import { ActionToolbar, AsanaCard, Loader, PrimaryButton } from "components";
import { usePosesList, useIntersectionObserver, useSearchPoses } from "hooks";
import { memo, useEffect } from "react";

import { useRef, useState } from "react";
import { useQueryClient } from "react-query";

import {
  useGetFilterData,
  useGetSearchData,
  useSetFilterDataHandler,
  useSetSearchDataHandler,
} from "store";
import { IPose } from "types";

import classNames from "classnames";

function CardPicker() {
  const defaultPageLimit = 30;
  const filterArray = useGetFilterData();
  const setFilterArray = useSetFilterDataHandler();
  // const [filterArray, setFilterArray] = useState<Array<string>>([]);
  const [cursor, setCursor] = useState(1);

  // const posesResult = useGetPosesResultData();
  // const setPosesResult = useSetPosesResultDataHandler();
  // const searchPosesResult = useGetSearchPosesResultData();
  // const setSearchPosesResult = useSetSearchPosesResultDataHandler();

  const [dataArray, setDataArray] = useState<IPose[] | []>([]);
  const [searchedDataArray, setSearchedDataArray] = useState<IPose[] | []>([]);

  const loadMoreButtonRef = useRef<HTMLButtonElement | null>(null);
  const search = useGetSearchData();
  const setSearchData = useSetSearchDataHandler();
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries("posesList");
    queryClient.invalidateQueries("searchPoses");

    // setPosesResult([]);
    // setSearchPosesResult([]);
    setDataArray([]);
    setSearchedDataArray([]);

    setCursor(1);
  }, [queryClient, filterArray]);

  useEffect(() => {
    queryClient.invalidateQueries("posesList");
    queryClient.invalidateQueries("searchPoses");

    // setPosesResult([]);
    // setSearchPosesResult([]);
    setSearchedDataArray([]);
    setDataArray([]);

    setCursor(1);
  }, [queryClient, search.searchString]);

  const {
    data: searchData,
    status: searchStatus,
    isFetching: searchFetching,
  } = useSearchPoses({
    search: search.searchString,
    enabled: !!search.searchString && dataArray.length < 1,
    skip: defaultPageLimit * (cursor - 1),
    pageLimit: defaultPageLimit,
    cursor,
    filterArray,
  });

  const { data, status, isFetching } = usePosesList({
    skip: defaultPageLimit * (cursor - 1),
    pageLimit: defaultPageLimit,
    cursor,
    enabled: !search.searchString && searchedDataArray.length < 1,
    filterArray,
  });

  useEffect(() => {
    if (data?.pages[0].data) {
      // setSearchPosesResult([]);
      // setPosesResult((prevValue) => [...prevValue, ...data?.pages[0].data]);
      setSearchedDataArray([]);
      setDataArray((prevValue) => [...prevValue, ...data?.pages[0].data]);
    }
  }, [data]);

  useEffect(() => {
    if (searchData?.pages[0].data) {
      // setPosesResult([]);
      // setSearchPosesResult((prevValue) => [
      //   ...prevValue,
      //   ...searchData?.pages[0].data,
      // ]);
      setDataArray([]);
      setSearchedDataArray((prevValue) => [
        ...prevValue,
        ...searchData?.pages[0].data,
      ]);
    }
  }, [searchData]);

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: setCursor,
    enabled:
      search.searchString.length < 1 &&
      status !== "loading" &&
      !isFetching &&
      data &&
      data.pages &&
      data.pages[0].pages > cursor &&
      data.pages[0].data.length >= defaultPageLimit - 1,
  });

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: setCursor,
    enabled:
      search.searchString.length > 0 &&
      searchStatus !== "loading" &&
      !searchFetching &&
      searchData &&
      searchData.pages &&
      searchData.pages[0].pages > cursor &&
      searchData.pages[0].data.length >= defaultPageLimit - 1,
  });

  const [showNoResults, setShowNoResults] = useState(false);
  useEffect(() => {
    if (
      !searchFetching &&
      !isFetching &&
      status !== "loading" &&
      searchStatus !== "loading" &&
      search.searchString.length > 1 &&
      searchedDataArray.length < 1
    ) {
      setShowNoResults(true);
    } else {
      setShowNoResults(false);
    }
  }, [
    isFetching,
    searchFetching,
    searchedDataArray,
    status,
    searchStatus,
    search.searchString,
  ]);

  return (
    <div className="flex bg-white  flex-col  justify-center w-full h-full pb-4">
      <ActionToolbar
        filterArray={filterArray}
        setFilterArray={setFilterArray}
        toolbarType="picker"
      />
      <div className=" pt-8">
        <div
          className={classNames(
            "w-full p-8 h-full flex flex-col flex-1 flex-grow justify-start mt-[8rem] items-center",
            {
              hidden: !showNoResults,
            }
          )}
        >
          <div className="  flex flex-row  items-center justify-center">
            <div className="flex mr-6">
              <FontAwesomeIcon
                className="fa-2x text-warn"
                icon={faExclamationTriangle}
              />
            </div>
            <p className="font-bold text-3xl">No search results</p>
          </div>
          <div className="mt-7">
            <p className="font-normal text-xl text-black text-center opacity-50 max-w-[890px]">
              General tip: If you can’t find a specific pose in our library you
              will also see “no search results”. We add new poses regularly.
            </p>
          </div>
          <div className="mt-14">
            <PrimaryButton
              fontType="normal"
              onClick={() => {
                setShowNoResults(false);
                let input1 = document.getElementById(
                  "searchInput1"
                ) as HTMLInputElement;
                let input2 = document.getElementById(
                  "searchInput2"
                ) as HTMLInputElement;
                setSearchData({ searchString: "" });

                input1.value = "";
                input2.value = "";
              }}
              //buttonType="deny"
              option="Show all cards"
            />
          </div>
        </div>
        <div
          className={classNames(
            " h-full maxSm:justify-center auto-rows-max grid-cols-1 xs:grid-cols-2  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full pb-[4.5rem] pt-[1.5rem] px-5 sm:px-10 grid gap-[21px] sm:gap-[41px] ",
            {
              hidden: showNoResults,
            }
          )}
        >
          {searchedDataArray.length < 1
            ? dataArray.map((card, i) => {
                return (
                  <AsanaCard key={card.name + i} cardData={card} index={i} />
                );
              })
            : searchedDataArray.map((card, i) => {
                return (
                  <AsanaCard key={card.name + i} cardData={card} index={i} />
                );
              })}
          {/* {searchData?.pages[0]?.data.length >= 1 &&
          searchPosesResult.map((card, i) => {
            return <AsanaCard  cardData={card} index={i} />;
          })}
        {search.searchString.length < 1 &&
          searchPosesResult.length < 1 &&
          posesResult.map((card, i) => {
            return <AsanaCard  cardData={card} index={i} />;
          })} */}
        </div>

        <div
          className={classNames("relative w-full min-h-[80vh] h-full", {
            hidden: !isFetching,
          })}
        >
          <Loader globalLoader={false} />
        </div>

        <button
          className="invisible"
          ref={loadMoreButtonRef}
          onClick={() => {
            if (data && data.pages && data.pages[0] && data.pages[0].pages) {
              if (cursor < data?.pages[0].pages) {
                setCursor((val) => val + 1);
              }
            }
          }}
        >
          Load more
        </button>
      </div>
    </div>
  );
}

export default memo(CardPicker);
