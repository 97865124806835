function Test() {
  return (
    <div className="flex flex-col items-center justify-center w-full pt-16 pb-20">
      <div className=" flex text-xl font-bold w-full h-full justify-center items-center">
        TEST
      </div>
    </div>
  );
}

export default Test;
