import classnames from "classnames";
import {
  ActionModal,
  ActionModalDelete,
  ActionToolbarFilterCheckbox,
  Input,
  PDFSequence,
  PrimaryButton,
  ToolbarDropdownButton,
  ToolbarFilterButton,
  ToolbarRoundButton,
  ToolbarSquareButton,
  TutorialNotification,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandSparkles,
  faShareAlt,
  faCloudDownloadAlt,
  faPlayCircle,
  faLightbulbOn,
  faQuestion,
  faArrowLeft,
  faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ASANA_FILTER } from "const";
import {
  useGetSearchData,
  useGetSequenceData,
  useSequenceDataHandler,
  useSetSearchDataHandler,
} from "store";
import { useDebounce } from "hooks";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Cookies from "universal-cookie";
import { saveAs } from "file-saver";

import ReactPDF from "@react-pdf/renderer";
import { format } from "date-fns";
import notificationImage from "./../../assets/images/tutorial-notification-image.jpg";
import savingDisabledIcon from "./../../assets/images/saving-disabled.svg";
import { SetterOrUpdater } from "recoil";

interface ActionToolbarProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  filterArray?: string[];
  setFilterArray?: SetterOrUpdater<string[]>;
  toolbarType?: "picker" | "builder";
}

export function ActionToolbar({
  filterArray,
  setFilterArray,
  toolbarType,
}: ActionToolbarProps) {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);
  const [showTutorialNotification, setShowTutorialNotification] =
    useState(false);
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("visited")) {
      setShowTutorialNotification(false);
    } else {
      setShowTutorialNotification(true);
      cookies.set("visited", "true");
    }
  }, []);

  const navigate = useNavigate();

  const sequenceData = useGetSequenceData();
  const setSequences = useSequenceDataHandler();

  const [searchString, setSearchString] = useState("");
  const [sequenceName, setSequenceName] = useState(sequenceData.sequenceName);
  const debouncedSearch = useDebounce(300, setSearchString);
  const debouncedSequenceName = useDebounce(300, setSequenceName);
  const search = useGetSearchData();
  const setSearch = useSetSearchDataHandler();
  const setSequence = useSequenceDataHandler();

  useEffect(() => {
    setSearch({ searchString: searchString });
  }, [searchString, setSearch]);
  useEffect(() => {
    setSequence((val) => ({ ...val, sequenceName: sequenceName }));
  }, [setSequence, sequenceName]);

  const [toDefault, setToDefault] = useState(false);

  const [pdfDocument, setPdfDocument] = useState(
    <PDFSequence
      sequenceName={
        sequenceData?.sequenceName?.length > 0
          ? sequenceData.sequenceName
          : "My sequence"
      }
      cardSequence={sequenceData.sequence}
    />
  );

  useEffect(() => {
    setPdfDocument(
      <PDFSequence
        sequenceName={
          sequenceData?.sequenceName?.length > 0
            ? sequenceData.sequenceName
            : "My sequence"
        }
        cardSequence={sequenceData.sequence}
      />
    );
  }, [sequenceData.sequenceName, sequenceData.sequence]);

  const renderUrl = async () =>
    new Promise(async (resolve, reject) => {
      const blob = await ReactPDF.pdf(pdfDocument).toBlob();
      saveAs(
        blob,
        `SequenceBuilder_${sequenceData.sequenceName}_${format(
          new Date(),
          "dd.MM.yyyy"
        ).toString()}.pdf`
      );
      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        resolve(url);
      }
    })
      .then((res) => res)
      .catch((err) => console.log(err));

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    if (viewportWidth > 560) {
      if (viewportHeight < 645) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    } else {
      setIsSmallScreen(false);
    }
  }, [viewportHeight, viewportWidth]);

  return toolbarType === "builder" ? (
    <div className=" sm:shadow-popup bg-cardTitleBg maxSm:pt-[1.5rem] sm:bg-white flex flex-col minHeaderSize:flex-row justify-between   max-h-[7rem]  w-full  px-4 py-6 ">
      <div className="flex flex-col sm:flex-row justify-center items-center sm:justify-between minHeaderSize:justify-start gap-1">
        <div className="flex items-center flex-row w-full md:w-auto">
          <Input
            onChange={(e) => {
              debouncedSequenceName(e.target.value);
            }}
            defaultValue={sequenceData.sequenceName}
            classNames={{
              container:
                "sm:mr-4 border-gainsboro hover:border-gold focus:border-gold",
            }}
            placeholder="Name of your sequence"
          />
        </div>
        <div className="flex items-center  flex-row mt-3  sm:mt-0">
          <ToolbarRoundButton
            isOnTheToolbar={true}
            onClick={() => {
              setShowClearModal((val) => !val);
            }}
            popupType="regular"
            popupPosition="right"
            popupContent={
              <div className="rounded-[10px] ">
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-t-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-4 w-full flex-row")}
                    onClick={() => {
                      document.location.href = "https://tintyoga.com/contact/";
                    }}
                  >
                    <p className="text-darkBlue">Support</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-t-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-4 w-full flex-row")}
                    onClick={() => {
                      document.location.href =
                        "https://help.tintyoga.com/portal/en/kb/sequence-builder";
                    }}
                  >
                    <p className="text-darkBlue">Help-Center</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-none border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-b-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-4 w-full flex-row")}
                    onClick={() => {
                      setShowTutorialModal((val) => !val);
                    }}
                  >
                    <p className="text-darkBlue">How to use</p>
                  </button>
                </div>
              </div>
            }
            tooltipText="Clear Canvas"
            classNames={{ mainContainer: " sm:mr-4 mr-6 " }}
          >
            <div>
              <div style={{ fontSize: "0.875rem" }} className="hidden sm:flex">
                <FontAwesomeIcon
                  className="fa-lg text-nero hover:text-gold group-hover:text-gold"
                  icon={faHandSparkles}
                />
              </div>
              <div style={{ fontSize: "0.875rem" }} className="flex sm:hidden">
                <FontAwesomeIcon
                  className="fa-sm text-nero hover:text-gold group-hover:text-gold"
                  icon={faHandSparkles}
                />
              </div>
            </div>
          </ToolbarRoundButton>
          <ToolbarRoundButton
            isOnTheToolbar={true}
            popupType="regular"
            className="export-to-pdf-button" // multiple export-to-pdf-button classes  need for gtm
            tooltipText="Export to PDF"
            onClick={() => {
              //@ts-ignore
              window.dataLayer.push({ event: "on-export-button-click" });
              renderUrl();
            }}
            classNames={{ mainContainer: " sm:mr-4 mr-6 " }}
          >
            <div className="">
              <div style={{ fontSize: "0.875rem" }} className="hidden sm:flex ">
                <FontAwesomeIcon
                  className="fa-lg text-nero hover:text-gold group-hover:text-gold "
                  icon={faCloudDownloadAlt}
                />
              </div>
              <div style={{ fontSize: "0.875rem" }} className="flex sm:hidden ">
                <FontAwesomeIcon
                  className="fa-sm text-nero  hover:text-gold group-hover:text-gold"
                  icon={faCloudDownloadAlt}
                />
              </div>
            </div>
          </ToolbarRoundButton>
          <ToolbarRoundButton
            isOnTheToolbar={true}
            disable={true}
            popupType="regular"
            tooltipText="Regular saving is not available, but don’t worry your sequence will be saved for 7 days in your browser, when you close the window."
            classNames={{ mainContainer: " sm:mr-4 mr-6" }}
            className="border-gainsboro"
          >
            <div>
              <div style={{ fontSize: "0.875rem" }} className="hidden sm:flex">
                <img
                  className=" h-[1.5rem] w-[1.5rem]"
                  alt="no-save"
                  src={savingDisabledIcon}
                />
                {/* <FontAwesomeIcon className="fa-lg" icon={faSave} /> */}
              </div>
              <div style={{ fontSize: "0.875rem" }} className="flex sm:hidden">
                <img
                  className=" h-[1rem] w-[1rem]"
                  alt="no-save"
                  src={savingDisabledIcon}
                />
                {/* <FontAwesomeIcon className="fa-sm" icon={faSave} /> */}
              </div>
            </div>
          </ToolbarRoundButton>
          <ToolbarRoundButton
            isOnTheToolbar={true}
            popupType="regular"
            tooltipText="Feedback and Ideas"
            onClick={() => {
              document.location.href =
                "https://tintyoga.com/sequence-builder-feedback/";
            }}
            classNames={{ mainContainer: "hidden sm:flex sm:mr-4 mr-6" }}
          >
            <div className="pt-1" style={{ fontSize: "0.875rem" }}>
              <FontAwesomeIcon
                className="fa-lg text-nero  hover:text-gold group-hover:text-gold"
                icon={faLightbulbOn}
              />
            </div>
          </ToolbarRoundButton>
          <ToolbarRoundButton
            isOnTheToolbar
            popupType="regular"
            popupPosition="right"
            popupContent={
              <div className="rounded-[10px] ">
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-t-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-3 w-full flex-row")}
                    onClick={() => {
                      document.location.href = "https://tintyoga.com/contact/";
                    }}
                  >
                    <p className="text-darkBlue pt-1">Support</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm   text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-3 w-full flex-row")}
                    onClick={() => {
                      document.location.href =
                        "https://help.tintyoga.com/portal/en/kb/sequence-builder";
                    }}
                  >
                    <p className="text-darkBlue pt-1">Help Center</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-none border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-b-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-6 py-3 w-full flex-row")}
                    onClick={() => {
                      setShowTutorialModal((val) => !val);
                    }}
                  >
                    <p className="text-darkBlue pt-1">How to use</p>
                  </button>
                </div>
              </div>
            }
            tooltipText="Help Center"
            classNames={{ mainContainer: "hidden sm:flex" }}
          >
            <div className="pt-1" style={{ fontSize: "0.875rem" }}>
              <FontAwesomeIcon
                className="fa-lg text-nero  hover:text-gold group-hover:text-gold"
                icon={faQuestion}
              />
            </div>
          </ToolbarRoundButton>
        </div>
      </div>
      <div className="hidden minHeaderSize:flex flex-row">
        <ToolbarSquareButton
          popupType="regular"
          classNames={{ mainContainer: "mr-2.5" }}
          option="Watch the tutorial"
          onClick={() => {
            setShowTutorialModal((val) => !val);
          }}
          className="hover:bg-[#F6F1EA] hover:rounded-[100px]"
        >
          <div style={{ fontSize: "0.875rem" }}>
            <FontAwesomeIcon
              className="fa-lg text-nero  hover:text-gold group-hover:text-gold"
              icon={faPlayCircle}
            />
          </div>
        </ToolbarSquareButton>
        <ToolbarSquareButton
          popupType="regular"
          popupContent={
            <div className="grid grid-flow-col gap-1 p-4">
              <FacebookShareButton
                onClick={() => {
                  //@ts-ignore
                  window.dataLayer.push({ event: "on-social-button-click" });
                }}
                className="social-button"
                quote="I found something awesome, take a look here"
                title="I found something awesome, take a look here"
                url="https://tools.tintyoga.com/sequence-builder"
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                onClick={() => {
                  //@ts-ignore
                  window.dataLayer.push({ event: "on-social-button-click" });
                }}
                className="social-button"
                title="I found something awesome, take a look here"
                url="https://tools.tintyoga.com/sequence-builder"
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <TelegramShareButton
                onClick={() => {
                  //@ts-ignore
                  window.dataLayer.push({ event: "on-social-button-click" });
                }}
                className="social-button"
                title="I found something awesome, take a look here"
                url="https://tools.tintyoga.com/sequence-builder"
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>

              <WhatsappShareButton
                onClick={() => {
                  //@ts-ignore
                  window.dataLayer.push({ event: "on-social-button-click" });
                }}
                className="social-button"
                title="I found something awesome, take a look here"
                url="https://tools.tintyoga.com/sequence-builder"
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
          }
          className="hover:bg-[#F6F1EA] hover:rounded-[100px]"
          option="Spread the Word"
        >
          <div style={{ fontSize: "0.875rem" }}>
            <FontAwesomeIcon
              className="fa-lg text-nero hover:text-gold group-hover:text-gold"
              icon={faShareAlt}
            />
          </div>
        </ToolbarSquareButton>
      </div>
      <ActionModal
        isShowing={showTutorialModal}
        title="Tutorial"
        customMaxWidth
        className="backdrop-filter  "
        onHide={() => {
          setShowTutorialModal(false);
        }}
        classNames={{
          subMainContainer: "  ",
          modalHeader: " ",
          childrenContainer: "   ",
          borderLine: "   ",
          closeButtonContainer: " ",
          closeButton: "",
          titleContainer: " ",
          title: "",
          subTitle: "",
        }}
      >
        <div className="flex flex-col flex-1 w-full h-full">
          <div className="flex p-6 justify-end items-center min-h-[350px] sm:min-h-[475px] w-full h-full aspect-w-16  flex-row  ">
            <div style={{ padding: "57% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/675809102?h=b3d9518a18&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Sequence Builder Tutorial.mov"
              ></iframe>
            </div>
          </div>
        </div>
      </ActionModal>
      <TutorialNotification
        isSmallScreen={isSmallScreen}
        isShowing={showTutorialNotification}
        title="Welcome"
        subTitle="Learn the basics of navigation with a 3-min video and explore the builder step-by-step."
        customMaxWidth
        className=" "
        onHide={() => {
          setShowTutorialNotification(false);
        }}
        classNames={{
          subMainContainer: "  ",
          modalHeader: " ",
          childrenContainer: "   ",
          borderLine: "   ",
          closeButtonContainer: " ",
          closeButton: "",
          titleContainer: " ",
          title: "",
          subTitle: "",
        }}
      >
        <div className="flex px-8 pb-8 flex-col flex-1 w-full h-full">
          <div className="flex relative justify-end items-center h-full w-full  flex-row rounded-[10px] overflow-hidden ">
            <img
              className="cursor-pointer"
              alt="img"
              src={notificationImage}
              onClick={() => {
                setShowTutorialNotification(false);
                setShowTutorialModal(true);
              }}
            />
            <div className="hidden absolute top-[40%] left-[46%]">
              <FontAwesomeIcon
                onClick={() => {
                  setShowTutorialNotification(false);
                  setShowTutorialModal(true);
                }}
                className="fa-3x text-white cursor-pointer"
                icon={faPlay}
              />
            </div>
          </div>
        </div>
      </TutorialNotification>
      <ActionModalDelete
        isShowing={showClearModal}
        title="Do you really want to clear the sequence"
        subTitle="All your selected poses will be cleared from the board and cannot be restored. You will have to start from scratch."
        customMaxWidth
        className="backdrop-filter  "
        onHide={() => {
          setShowClearModal(false);
        }}
        classNames={{
          subMainContainer: "  ",
          modalHeader: " ",
          childrenContainer: "   ",
          borderLine: "   ",
          closeButtonContainer: " ",
          closeButton: "",
          titleContainer: " ",
          title: "",
          subTitle: "",
        }}
      >
        <div className="flex bg-squareButtonToolbarBg flex-col flex-1 w-full h-full">
          <div className="flex p-6 justify-end items-center  w-full h-full   flex-row  ">
            <PrimaryButton
              fontType="normal"
              onClick={() => {
                setSequences((val) => ({ ...val, sequence: [] }));
                setShowClearModal(false);
              }}
              //buttonType="deny"
              option="Clear Board"
              className=" border-none "
              classNames={{
                buttonText: " font-normal",
              }}
            />
          </div>
        </div>
      </ActionModalDelete>
    </div>
  ) : toolbarType === "picker" ? (
    <div className="flex flex-col md:flex-row  bg-cardTitleBg sm:bg-white justify-between md:items-center px-4  max-h-[6.5rem]  w-full py-4 ">
      <div className="hidden sm:flex flex-row items-center">
        <ToolbarRoundButton
          isOnTheToolbar={true}
          //animate={false}
          popupType="regular"
          popupPosition="right"
          popupContent={undefined}
          onClick={() => {
            navigate(-1);
          }}
          classNames={{
            mainContainer: "mr-6",
            buttonSize: "w-[35px] h-[35px]",
          }}
        >
          <FontAwesomeIcon
            className="fa-md text-[0.875rem] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]  "
            icon={faArrowLeft}
          />
        </ToolbarRoundButton>
        <p className="text-lg font-bold whitespace-nowrap mr-2">
          Back to your sequence
        </p>
      </div>
      <div className="hidden md:flex flex-row items-center max-w-[528px] w-full h-full ">
        <Input
          id="searchInput1"
          setValue={setSearchString}
          search={search}
          ownValue={searchString}
          inputType="search"
          classNames={{
            container: "  h-full max-w-[528px] mr-[10px] w-full ",
            input: "pr-[15px]",
          }}
          onChange={(e) => {
            debouncedSearch(e.target.value);
          }}
          placeholder="Search by name or sanskrit …"
        />
      </div>
      <div className="flex pt-0 sm:pt-4 md:pt-0  flex-col sm:flex-row maxSm:justify-center items-center">
        <div className="flex flex-row w-full justify-start">
          <ToolbarRoundButton
            isOnTheToolbar={true}
            //animate={false}
            showWhiteBackground={false}
            popupType="regular"
            popupPosition="right"
            popupContent={undefined}
            onClick={() => {
              navigate(-1);
            }}
            classNames={{
              mainContainer: "flex  sm:hidden mr-6 ",
              buttonSize: "w-[35px] h-[35px]",
            }}
          >
            <div style={{ fontSize: "0.875rem" }}>
              <FontAwesomeIcon className="fa-md" icon={faArrowLeft} />
            </div>
          </ToolbarRoundButton>
          <Input
            id="searchInput2"
            setValue={setSearchString}
            search={search}
            ownValue={searchString}
            inputType="search"
            onChange={(e) => {
              debouncedSearch(e.target.value);
            }}
            classNames={{
              container:
                "  flex md:hidden mr-[10px] md:mr-[3rem]   h-12 max-w-[528px] w-full",

              input: "pr-[15px]",
            }}
            placeholder="Search by name or sanskrit …"
          />
        </div>

        <div className="flex pt-4 sm:pt-0 flex-row items-center">
          <ToolbarFilterButton
            popupType="regular"
            option="Clear Filter"
            onClick={() => {
              if (setFilterArray) {
                setFilterArray([]);
              }

              setToDefault(true);
            }}
            className={classnames(
              " ml-1 mr-1 h-10 sm:h-12 md:h-full opacity-50 hover:opacity-100 ",
              {
                hidden: filterArray && filterArray.length < 1,
              }
            )}
          >
            <FontAwesomeIcon
              className="fa-lg text-alert"
              icon={faTimesCircle}
            />
          </ToolbarFilterButton>
          <ToolbarDropdownButton
            popupType="regular"
            popupPosition="right"
            option="Asana Type"
            popupContent={
              <div className="flex flex-col maxSm:max-h-[300px] overflow-y-scroll rounded-[10px] p-4 z-50 ">
                {filterArray &&
                  setFilterArray &&
                  Object.values(ASANA_FILTER).map((value, i) => {
                    const initialValue = !!filterArray?.find(
                      (item) => item === value
                    );

                    return (
                      <ActionToolbarFilterCheckbox
                        key={value + i}
                        index={i}
                        initialValue={initialValue}
                        setToDefault={setToDefault}
                        toDefault={toDefault}
                        filterArray={filterArray}
                        setFilterArray={setFilterArray}
                        name={value}
                      />
                    );
                  })}
              </div>
            }
            className=" absolute top-0  "
          >
            <FontAwesomeIcon
              className="fa-md text-black"
              icon={faChevronDown}
            />
          </ToolbarDropdownButton>
        </div>
      </div>
    </div>
  ) : null;
}

export default ActionToolbar;
