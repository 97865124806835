import axios from "axios";

import { useInfiniteQuery } from "react-query";
import { searchPoses } from "services";

interface IQueryProps {
  pageLimit: number;
  cursor: number;
  skip: number;
  filterArray: Array<string>;
  search: string | undefined | null;
  enabled: boolean;
}

export function useSearchPoses({
  pageLimit,
  cursor,
  skip,
  filterArray,
  search,
  enabled,
  ...rest
}: IQueryProps) {
  return useInfiniteQuery(
    ["searchPoses", pageLimit, cursor, skip, enabled, filterArray, search],
    async ({ pageParam = pageLimit }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return (
        await searchPoses({
          pageParam,
          cursor,
          skip,
          filterArray,
          source,
          search,
        })
      ).data;
    },
    //@ts-ignore
    {
      enabled: enabled,
      getPreviousPageParam: (firstPage) => firstPage.previousPage ?? false,
      getNextPageParam: (lastPage) => lastPage.nextPage ?? false,

      refetchOnWindowFocus: false,
      keepPreviousData: false,
      staleTime: 5000,
      ...rest,
      suspense: false,
    }
  );
}
