import logoRegular from "assets/images/logo.png";

function MobileSplash() {
  return (
    <div className="flex h-full w-full m-auto justify-start flex-col p-2 mb-16 max-w-[500px]">
      <div className="mb-10 mt-8">
        <img
          alt="logo"
          className="min-w-[150px] w-[150px]"
          src={logoRegular}
        ></img>
      </div>
      <div className="text-left mb-8">
        <h1 className="text-xl font-bold text-darkBlue mb-4">Hinweis</h1>
        <p className="text-darkBlue pb-4 font-light">
          TintYoga ist vorerst nur über die Desktop-Ansicht (Computer / Laptop /
          Tablet) optimal nutzbar und ist nicht für mobile Geräte optimiert.
          Viele Verwaltungsfunktionen benötigen ausreichend Platz zur
          Darstellung aller relevanten Mitgliederdaten.
        </p>
        <p className="text-darkBlue pb-4 font-light">
          Wir bitten daher um Verständnis, dass zum jetzigen Zeitpunkt noch
          keine mobile Version verfügbar ist. Wir arbeiten bereits an neuen
          Features und die mobile Optimierung der Applikation steht ganz oben
          auf unserer Liste.{" "}
        </p>
        <p className="text-darkBlue pb-4 font-light">
          Falls du Fragen hast, schreibe uns gerne im Chat oder klicke unten auf
          den Button, um alle Kontaktoptionen zu sehen.
        </p>
      </div>
      <button
        onClick={() => {
          window.open("https://google.de", "_blank");
        }}
        className="w-auto inline-block  mr-5 z-10 transition-all duration-300 ease-in-out"
      >
        Kontaktoptionen anzeigen
      </button>
    </div>
  );
}

export default MobileSplash;
