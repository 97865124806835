import { atom, useSetRecoilState, useRecoilValue } from "recoil";

import { STORE } from "const";
import { ISearchString } from "types";

export const isSearchState = atom<ISearchString>({
  key: STORE.SEARCH,
  default: { searchString: "" },
});

export function useSetSearchDataHandler() {
  return useSetRecoilState(isSearchState);
}

export function useGetSearchData() {
  return useRecoilValue(isSearchState);
}
