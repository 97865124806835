import axios from "axios";

import { useInfiniteQuery } from "react-query";
import { getPosesList } from "services";

interface IQueryProps {
  pageLimit: number;
  cursor: number;
  skip: number;
  filterArray: Array<string>;
  enabled?: boolean;
}

export function usePosesList({
  pageLimit,
  cursor,
  skip,
  filterArray,
  enabled = true,
  ...rest
}: IQueryProps) {
  return useInfiniteQuery(
    ["posesList", pageLimit, cursor, skip, enabled, filterArray],
    async ({ pageParam = pageLimit }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return (
        await getPosesList({ pageParam, cursor, skip, filterArray, source })
      ).data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousPage ?? false,
      getNextPageParam: (lastPage) => lastPage.nextPage ?? false,
      enabled: enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: enabled,
      staleTime: 5000,
      ...rest,
      suspense: false,
    }
  );
}
