import classnames from "classnames";
import { Burger, Menu, PrimaryButton } from "components";
import { APP_ROUTES } from "const";
import { useState } from "react";
import { useNavigate } from "react-router";
import logo from "assets/images/logo.png";

interface IFooter {
  className?: string;
}

function HeaderMenuItemExternalLink({
  text,
  href,
}: {
  text: string;
  href: string;
}) {
  return (
    <a
      className="font-poppins tracking-normal leading-[39px] text-darkBlue05 hover:text-darkBlue  mr-[31px] header-menu-item font-normal text-base"
      href={href}
      rel="noreferrer"
    >
      {text}
    </a>
  );
}

function Header({ className }: IFooter) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="w-full border-b border-gainsboro ">
      <div
        className={classnames(
          className,
          "flex flex-col relative minHeaderSize:flex-row items-center w-full h-full min-h-[4.5rem] lg:min-h-[5rem] max-h-[5rem] bg-white  z-80  "
        )}
      >
        <div className="flex relative items-center justify-between pl-4  min-h-[4.5rem] lg:min-h-[5rem]   w-full minHeaderSize:max-w-[7.5rem]    ">
          <div
            onClick={() => {
              navigate(APP_ROUTES.HOME);
            }}
            className="flex justify-start items-center z-40 w-full cursor-pointer  "
          >
            <img alt="logo " className="h-[35px]  " src={logo} />
          </div>

          <div className="relative minHeaderSize:hidden w-[32px] h-[32px] shrink-0 mr-4 ">
            <Burger
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              className="w-[25px] h-[25px]  absolute"
            />
          </div>
          <div className=" border-r border-gainsboro small-line hidden minHeaderSize:flex h-[35px]" />
        </div>

        <div className="hidden minHeaderSize:flex flex-row justify-between items-center w-full pl-10 pr-4 py-[1rem]  ">
          <div className="flex flex-col items-center">
            <p className="text-black  font-bold text-xl whitespace-nowrap mr-4">
              Sequence Builder
            </p>
          </div>
          <div className="flex flex-row items-center ">
            <HeaderMenuItemExternalLink
              href="https://insideyoga.org/"
              text="Home"
            />
            <HeaderMenuItemExternalLink
              href="https://insideyoga.org/inside-online/"
              text="Inside Online"
            />
            <HeaderMenuItemExternalLink
              text="Online Training"
              href="https://tintyoga.com/academy/"
            />
            <PrimaryButton
              fontType="normal"
              onClick={() => {
                document.location.href = "https://insideyoga.org/get-inside";
              }}
              //buttonType="deny"
              option="Get Inside"
              className=" border-none "
            />
          </div>
        </div>
      </div>
      <Menu openMenu={openMenu} />
    </div>
  );
}

export default Header;
