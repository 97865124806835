import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faArrowsAlt,
  faClone,
  faTrashAlt,
} from "@fortawesome/pro-duotone-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { IPose } from "types";
import { useSequenceDataHandler } from "store";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "const";
import { GridItem } from "react-grid-dnd";

interface AsanaCardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };
  isDraggable?: boolean;
  cardData: IPose;
  index: number;
  duplicateCard?: () => void;
  removeCard?: () => void;
  inputType?: string;
  componentKey?: string;
  placeholder?: string;
  isPicker?: boolean;
  boxesPerRow?: number;
}

function useParagraphOverflow(myRef: React.RefObject<any>) {
  const [isOverflow, setOverflow] = useState(false);

  useEffect(() => {
    const getOverflow = () =>
      0 > myRef.current.clientWidth - myRef.current.scrollWidth;

    const handleResize = () => {
      setOverflow(getOverflow());
    };

    if (myRef.current) {
      setOverflow(getOverflow());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return isOverflow;
}

export function AsanaCard({
  customRef,
  classNames,
  cardData,
  isDraggable = false,
  inputType,
  placeholder,
  duplicateCard,
  removeCard,
  componentKey,
  boxesPerRow = 5,
  isPicker = true,
  index,
  ...rest
}: AsanaCardProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const onTouchMove: { [key: string]: any } = {
    onTouchMove: () => {
      console.log("PRevnter");
    },
  };

  const nameRef = useRef<HTMLParagraphElement | null>(null);

  const sanskritRef = useRef<HTMLParagraphElement | null>(null);

  const isOverflow = useParagraphOverflow(nameRef);
  const isSanskritOverflow = useParagraphOverflow(sanskritRef);

  const [cardHovered, setCardHovered] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const navigate = useNavigate();
  const addToSequence = useSequenceDataHandler();
  if (cardHovered) {
    delete onTouchMove.onTouchMove;
  }
  return isDraggable ? (
    <GridItem
      onMouseOver={() => {
        setCardHovered(true);
      }}
      onMouseOut={() => {
        setCardHovered(false);
      }}
      style={{
        padding: "20px",
        paddingLeft: boxesPerRow < 3 ? "10px" : "20px",
        paddingRight: boxesPerRow < 3 ? "10px" : "20px",
        paddingBottom: "40px",
        zIndex: cardHovered ? 1 : 0,
        opacity: 1,
        touchAction: cardHovered ? "none" : "",
      }}
      {...rest}
      {...onTouchMove}
    >
      <span
        key={"spanTool"}
        className={classnames(
          "absolute   top-[-1.25rem] w-full flex flex-row justify-between pl-2 pr-12 ",
          {
            hidden: !cardHovered,
          }
        )}
      >
        <div className="flex flex-row justify-start" key={"toolArrows"}>
          <FontAwesomeIcon
            className="fa-lg opacity-50 hover:opacity-100 mr-2  sm:mr-7 cursor-move"
            icon={faArrowsAlt}
          />
          <FontAwesomeIcon
            onClick={duplicateCard}
            className="fa-lg opacity-50 hover:opacity-100 cursor-pointer"
            icon={faClone}
          />
        </div>
        <div key={"toolTrash"}>
          <FontAwesomeIcon
            onClick={removeCard}
            className="fa-lg text-alert opacity-50 hover:opacity-100 cursor-pointer "
            icon={faTrashAlt}
          />
        </div>
      </span>
      <div
        ref={ref}
        onTouchStart={(e) => e.stopPropagation()}
        key="mainDiv"
        onClick={() => {
          if (isPicker) {
            addToSequence((prevValue) => ({
              ...prevValue,
              sequence: [...prevValue.sequence, cardData],
            }));
            setTimeout(() => {
              navigate(APP_ROUTES.SEQUENCE_BUILDER);
            }, 100);
          }
        }}
        className={classnames(
          "relative flex flex-col bg-white h-full items-center min-h-[13.125rem] md:min-h-[18.25rem] rounded-[20px] overflow-hidden   pt-[1.188rem] border border-darkBlue01 cursor-move ",
          {
            "justify-between": isPicker && !cardHovered,
            " justify-between": !isPicker,
            "justify-end": isPicker && cardHovered,
          },
          classNames?.container
        )}
      >
        <span
          className={classnames(
            "absolute top-3 left-3 w-[2.125rem] h-[2.125rem] rounded-full bg-[#F5F5F6] flex justify-center items-center text-center select-none",
            {
              hidden: isPicker,
              flex: !isPicker,
            }
          )}
        >
          <p className="text-center pt-[3px] text-nero">{index + 1}</p>
        </span>

        {isPicker && (
          <img
            unselectable="on"
            className={classnames(
              " max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem] z-30 md:max-w-[11.711rem] pointer-events-none select-none",
              {
                hidden: isPicker && cardHovered,
                flex: isPicker && !cardHovered,
              }
            )}
            alt="card"
            src={cardData.cardImage}
          />
        )}
        {!isPicker && (
          <img
            unselectable="on"
            className={classnames(
              " max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem] z-30   md:max-w-[11.711rem] pointer-events-none select-none"
            )}
            alt="card"
            src={cardData.cardImage}
          />
        )}

        {isPicker && (
          <div
            className={classnames(
              "  max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem]  z-30  md:max-w-[11.711rem] pointer-events-none",
              {
                hidden: !cardHovered,
                flex: cardHovered,
              }
            )}
          >
            <FontAwesomeIcon
              className="fa-3x text-[#DDBC93]   "
              icon={faPlusCircle}
            />
          </div>
        )}

        {isPicker && (
          <div
            className={classnames("mt-7 mb-[3.3rem]", {
              hidden: isPicker && !cardHovered,
              flex: isPicker && cardHovered,
            })}
          >
            <p className="text-md font-bold text-gold  ">Add pose</p>
          </div>
        )}

        <div
          onMouseOver={() => {
            if (isOverflow || isSanskritOverflow) {
              setShowToolTip(true);
            }
          }}
          onMouseOut={() => {
            setShowToolTip(false);
          }}
          className={classnames(
            "flex flex-col  w-full justify-center items-center bg-cardTitleBg  p-2  "
          )}
        >
          <p
            ref={nameRef}
            className="font-bold text-xs pt-1 md:text-sm overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[97%]"
          >
            {cardData.name}
          </p>
          <p
            ref={sanskritRef}
            className=" italic font-normal  text-xs overflow-hidden w-full text-center overflow-ellipsis whitespace-nowrap max-w-[97%]"
          >
            {cardData.sanskrit}
          </p>
        </div>
      </div>
      {(isOverflow || isSanskritOverflow) && (
        <span
          className={classnames(
            `absolute bg-tooltipBg   text-darkBlue  min-w-full max-w-[120%] left-[50%] translate-x-[-50%] break-words text-center rounded-[6px] px-2 py-1.5 z-[100] top-[90%]   font-bold after:tooltiptoparrow`,
            {
              invisible: !showToolTip,
              visible: showToolTip,
            }
          )}
        >
          <p className="font-bold text-sm h-full   ">{cardData.name}</p>
          <p className=" italic font-normal h-full text-sm   ">
            {cardData.sanskrit}
          </p>
        </span>
      )}
    </GridItem>
  ) : (
    <div
      ref={ref}
      className="relative asana-card"
      onClick={() => {
        //@ts-ignore
        window.dataLayer.push({ event: "on-asana-card-click" });
      }}
    >
      <div
        onMouseOver={() => {
          setCardHovered(true);
        }}
        onMouseOut={() => {
          setCardHovered(false);
        }}
        onClick={() => {
          if (isPicker) {
            addToSequence((prevValue) => {
              return {
                ...prevValue,
                sequence: [...prevValue?.sequence, cardData],
              };
            });
            setTimeout(() => {
              navigate(APP_ROUTES.SEQUENCE_BUILDER);
            }, 100);
          }
        }}
        className={classnames(
          " relative flex flex-col transition-all duration-300 ease-in-out  h-full items-center min-h-[13.125rem] md:min-h-[18.25rem] rounded-[20px] overflow-hidden  pt-0 md:pt-[1.188rem] border border-darkBlue01 cursor-pointer ",
          {
            "justify-between": isPicker && !cardHovered,
            " justify-between": !isPicker,
            "justify-end": isPicker && cardHovered,
            "border-[#F6F1EA] ": cardHovered,
          },
          classNames?.container
        )}
      >
        <span
          className={classnames(
            "absolute top-3 left-3 w-[2.125rem] h-[2.125rem] transition-all duration-300 ease-in-out rounded-full bg-cardIndexBg flex justify-center items-center text-center select-none",
            {
              hidden: isPicker,
              flex: !isPicker,
            }
          )}
        >
          <p className="text-center pt-[3px] ">{index + 1}</p>
        </span>

        {isPicker && (
          <img
            unselectable="on"
            className={classnames(
              "max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem]  md:max-w-[11.711rem] pointer-events-none select-none",
              {
                "absolute  top-0 md:top-[1.1875rem] z-10 opacity-10":
                  cardHovered,
                flex: !cardHovered,
              }
            )}
            alt="card"
            src={cardData.cardImage}
          />
        )}
        {!isPicker && (
          <img
            unselectable="on"
            className={classnames(
              " max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem]  md:max-w-[11.711rem] pointer-events-none select-none"
            )}
            alt="card"
            src={cardData.cardImage}
          />
        )}

        {isPicker && (
          <div
            className={classnames(
              " max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem] transition-all duration-300 ease-in-out  md:max-w-[11.711rem] pointer-events-none z-20",
              {
                hidden: !cardHovered,
                flex: cardHovered,
              }
            )}
          >
            <FontAwesomeIcon className="fa-3x text-gold " icon={faPlusCircle} />
          </div>
        )}

        {isPicker && (
          <div
            className={classnames(
              " mt-3 mb-7 md:mt-7 md:mb-[3.3rem] transition-all duration-300 ease-in-out z-20",
              {
                hidden: !cardHovered,
                flex: cardHovered,
              }
            )}
          >
            <p className="text-md font-bold text-gold ">Add pose</p>
          </div>
        )}

        <div
          onMouseOver={() => {
            if (isOverflow || isSanskritOverflow) {
              setShowToolTip(true);
            }
          }}
          onMouseOut={() => {
            setShowToolTip(false);
          }}
          className={classnames(
            "flex flex-col  w-full justify-center transition-all duration-300 ease-in-out items-center bg-cardTitleBg  p-2  ",
            {
              "bg-[#F6F1EA] text-gold": cardHovered,
            }
          )}
        >
          <p
            ref={nameRef}
            className=" font-bold text-xs  pt-1 md:text-sm overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[97%]"
          >
            {cardData.name}
          </p>
          <p
            ref={sanskritRef}
            className=" italic font-normal   text-xs overflow-hidden w-full text-center overflow-ellipsis whitespace-nowrap max-w-[97%]"
          >
            {cardData.sanskrit}
          </p>
        </div>
      </div>
      {(isOverflow || isSanskritOverflow) && (
        <span
          className={classnames(
            `absolute bg-tooltipBg  text-darkBlue  min-w-full max-w-[120%] left-[50%] translate-x-[-50%] break-words text-center rounded-[6px] px-2 py-1.5 z-[100] top-[103%]   font-bold after:tooltiptoparrow`,
            {
              invisible: !showToolTip,
              visible: showToolTip,
            }
          )}
        >
          <p className="font-bold text-sm h-full ">{cardData.name}</p>
          <p className=" italic font-normal h-full text-sm  ">
            {cardData.sanskrit}
          </p>
        </span>
      )}
    </div>
  );
}

export default AsanaCard;
