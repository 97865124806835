import classnames from "classnames";
import { ActionToolbarPopup, Loader } from "components";
import { useOnClickOutside, useReturnCallbackWithStateChanger } from "hooks";
import { twMerge } from "tailwind-merge";

import React, { useRef, useState } from "react";
import { isTablet, isMobile } from "react-device-detect";

interface ToolbarRoundButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  customRef?: React.MutableRefObject<HTMLButtonElement | null>;
  applyActionPanelStyle?: boolean;
  formStatus?: any;
  classNames?: {
    className?: string;
    mainContainer: string;
    buttonText?: string;
    buttonSize?: string;
  };
  //animate?: boolean;
  popupContent?: JSX.Element;
  popupPosition?: "left" | "right" | "center";
  popupType?: "regular" | "share";
  tooltipText?: string;
  isOverrideType?: boolean;
  option?: string;
  buttonType?: string;
  disable?: boolean;
  isOnTheToolbar?: boolean;
  showWhiteBackground?: boolean;
  onClick?: () => void;
}

export function ToolbarRoundButton({
  option,
  buttonType,
  customRef,
  tooltipText,
  formStatus,
  classNames,
  popupContent,
  popupType,
  popupPosition,
  isOnTheToolbar = true,
  disable = false,
  //animate = true,
  showWhiteBackground = true,
  onClick,
  ...rest
}: ToolbarRoundButtonProps) {
  const [showToolTip, setShowToolTip] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const setShow = useReturnCallbackWithStateChanger(setShowPopup);
  const showPopupRef = useRef<HTMLDivElement | null>(null);
  const showPopupRefButton = useRef<HTMLButtonElement | null>(null);
  useOnClickOutside(showPopupRef, showPopupRefButton, () =>
    setShowPopup(false)
  );
  return popupType === "regular" ? (
    <div
      className={classnames(
        classNames?.mainContainer,
        "group relative maxSm:h-[45px]    hover:rounded-[50px]  rounded-full sm:bg-non p-[5px] sm:p-0",
        {
          "bg-white": showWhiteBackground,
        }
      )}
    >
      <button
        onMouseOver={(e) => {
          if (!isTablet && !isMobile && tooltipText) {
            setShowToolTip(true);
          }
        }}
        onMouseOut={() => {
          setShowToolTip(false);
        }}
        ref={customRef || showPopupRefButton}
        {...rest}
        disabled={disable}
        onClick={onClick || setShow}
        className={twMerge(
          classnames(
            "  flex items-center justify-center font-black transition-all transform duration-[250ms]  ease-linear rounded-[50px]  border border-gainsboro hover:border-gold    ",
            {
              " cursor-default opacity-50 border-transparent ": disable,
              "w-9 h-9 sm:w-[3rem] sm:h-[3rem] ": !classNames?.buttonSize,
              "hover:border-darkBlue": classNames?.buttonSize,
              "  ": !disable,
              // " hover:rounded-[10px]  ": animate,
            },
            classNames?.buttonSize,
            rest.className
          )
        )}
      >
        <div
          className={classnames(
            "max-w-[3rem] max-h-[3rem] sm:w-auto sm:h-auto relative",
            {
              hidden: !rest.children,
            }
          )}
        >
          {rest.children ? rest.children : null}
        </div>

        {!formStatus?.loading === true && option && (
          <p
            className={classnames(
              classNames?.buttonText,
              " mt-[2px] text-darkBlue  text-base whitespace-nowrap",
              {
                "text-deny": buttonType === "deny",
                "text-darkBlue": buttonType === "approve",
              }
            )}
          >
            {option}
          </p>
        )}
        {formStatus?.loading === true && (
          <div className="flex justify-center">
            <Loader />
          </div>
        )}
      </button>
      {tooltipText && (
        <span
          className={classnames(
            `absolute bg-[#F6F1EA] text-gold  max-w-[220px] left-[50%] translate-x-[-50%] break-words text-center rounded-[6px] px-2 py-1.5 z-[60] top-[115%]   font-bold after:tooltiptoparrow`,
            {
              invisible: !showToolTip,
              visible: showToolTip,
              "min-w-min": tooltipText.length < 10,
              "min-w-[140px]":
                tooltipText.length > 10 && tooltipText.length < 15,
              "min-w-[160px]":
                tooltipText.length > 15 && tooltipText.length < 30,
              "min-w-[200px]":
                tooltipText.length > 30 && tooltipText.length < 50,
              "min-w-[220px]": tooltipText.length > 50,
            }
          )}
        >
          <p className="text-sm ">{tooltipText}</p>
        </span>
      )}
      {popupContent && (
        <ActionToolbarPopup
          popupType={popupType}
          position={popupPosition}
          popupContent={popupContent}
          showPopup={showPopup}
          customRef={showPopupRef}
        />
      )}
    </div>
  ) : popupType === "share" ? (
    <div className={classnames("relative group", classNames?.mainContainer)}>
      <button
        onMouseOver={() => {
          if (tooltipText) {
            setShowToolTip(true);
          }
        }}
        onMouseOut={() => {
          setShowToolTip(false);
        }}
        ref={customRef || showPopupRefButton}
        {...rest}
        disabled={disable}
        onClick={onClick || setShow}
        className={classnames(
          " pt-1.5 flex items-center justify-center w-[3rem] h-[3rem]  font-black transition-all transform duration-[250ms]  ease-linear rounded-[50px] bg-roundedButtonToolbarBg border border-roundedButtonToolbarBorder   ",
          {
            "pointer-events-none opacity-50 border-transparent ": disable,
            "  ": !disable,
          },
          rest.className
        )}
      >
        <div
          className={classnames("", {
            hidden: !rest.children,
          })}
        >
          {rest.children ? rest.children : null}
        </div>

        {!formStatus?.loading === true && option && (
          <p
            className={classnames(
              classNames?.buttonText,
              " mt-[2px] text-darkBlue  text-base whitespace-nowrap",
              {
                "text-deny": buttonType === "deny",
                "text-darkBlue": buttonType === "approve",
              }
            )}
          >
            {option}
          </p>
        )}
        {formStatus?.loading === true && (
          <div className="flex justify-center">
            <Loader />
          </div>
        )}
      </button>
      {tooltipText && (
        <span
          className={classnames(
            `absolute bg-tooltipBg text-darkBlue  max-w-[220px] left-[50%] translate-x-[-50%] break-words text-center rounded-[6px] px-2 py-1.5 z-[60] top-[115%]   font-bold after:tooltiptoparrow`,
            {
              invisible: !showToolTip,
              visible: showToolTip,
              "min-w-min": tooltipText.length < 10,
              "min-w-[140px]":
                tooltipText.length > 10 && tooltipText.length < 15,
              "min-w-[160px]":
                tooltipText.length > 15 && tooltipText.length < 30,
              "min-w-[200px]":
                tooltipText.length > 30 && tooltipText.length < 50,
              "min-w-[220px]": tooltipText.length > 50,
            }
          )}
        >
          <p className="text-sm ">{tooltipText}</p>
        </span>
      )}
      {popupContent && (
        <ActionToolbarPopup
          isOnTheToolbar
          popupType={popupType}
          position={popupPosition}
          popupContent={popupContent}
          showPopup={showPopup}
          customRef={showPopupRef}
        />
      )}
    </div>
  ) : null;
}

export default ToolbarRoundButton;
