import classnames from "classnames";
import React from "react";

interface ActionToolbarPopupProps {
  customRef: React.MutableRefObject<HTMLDivElement | null>;
  popupHeader?: string;
  customStyles?: boolean;
  actionsPopup?: boolean;
  popupContent: JSX.Element;
  showPopup: boolean;
  className?: string;
  position?: "right" | "center" | "left";
}

function ActionToolbarFilterPopup({
  customRef,
  popupHeader,
  position,
  popupContent,
  showPopup,
  className,
}: ActionToolbarPopupProps) {
  return (
    <div
      ref={customRef}
      className={classnames(
        className,
        " absolute z-50 w-max-content sm:min-w-[313px] whitespace-nowrap  bg-white border rounded-[10px] mt-[15px] shadow-popup ",
        {
          hidden: !showPopup,
          "right-0": position === "right",
          "left-0": position === "left",
          "left-[50%] translate-x-[-50%]": position === "center",
        }
      )}
    >
      <div className="flex flex-col">
        <div
          hidden={!popupHeader}
          className={classnames(
            "h-[50px] border-b flex justify-start items-center p-4 mt-[5px] font-bold text-base pointer-events-none text-darkBlue",
            {
              hidden: !popupHeader,
            }
          )}
        >
          <h1>{popupHeader}</h1>
        </div>
        <div className="flex flex-row maxSm:justify-end">{popupContent}</div>
      </div>
    </div>
  );
}

export default ActionToolbarFilterPopup;
