import { atom, useSetRecoilState, useRecoilValue } from "recoil";

import { STORE } from "const";
import { IPose } from "types";

export const posesResultState = atom<IPose[]>({
  key: STORE.POSES_RESULT,
  default: [],
});

export function useSetPosesResultDataHandler() {
  return useSetRecoilState(posesResultState);
}

export function useGetPosesResultData() {
  return useRecoilValue(posesResultState);
}
