import ReactDOM from "react-dom";
import { Rings } from "react-loader-spinner";
import classNames from "classnames";

interface IProps {
  className?: string;
  portalNode?: HTMLElement;
  globalLoader?: boolean;
}

function LoaderComponent({
  className,
  portalNode = document.body,
  globalLoader = true,
}: IProps) {
  return globalLoader ? (
    ReactDOM.createPortal(
      <div
        className={classNames(className, {
          "z-20 top-0 absolute left-0 w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
            globalLoader,
          "z-20   w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
            !globalLoader,
        })}
      >
        <Rings color="#2E6164" height={300} width={300} />
      </div>,
      portalNode
    )
  ) : (
    <div
      className={classNames(className, {
        "z-20    w-full  h-full outline-none flex justify-center items-center overflow-y-hidden":
          !globalLoader,
      })}
    >
      <Rings color="#2E6164" height={50} width={50} />
    </div>
  );
}

export default LoaderComponent;
