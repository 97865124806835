import React from "react";
import { IPose } from "types";
import { Font, StyleSheet } from "@react-pdf/renderer";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import logo from "assets/images/logo.png";
import heart from "assets/images/heart.png";
//@ts-ignore
import poppinsReqular from "./../../assets/fonts/Poppins/Poppins-Regular.ttf";
//@ts-ignore
import poppinsItalic from "./../../assets/fonts/Poppins/Poppins-Italic.ttf";
//@ts-ignore
import poppinsBold from "./../../assets/fonts/Poppins/Poppins-Bold.ttf";
//@ts-ignore
import poppinsBlack from "./../../assets/fonts/Poppins/Poppins-Black.ttf";
import chunk from "lodash/chunk";
import { format } from "date-fns";

interface PDFDocumentProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };

  cardSequence: IPose[];
  sequenceName: string | undefined;
}
// h-full maxSm:justify-center auto-rows-max grid-cols-1 xs:grid-cols-2  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full py-[4.5rem] px-5 sm:px-10 grid gap-[21px] sm:gap-[41px]
Font.register({
  family: "Poppins",
  fonts: [
    { src: poppinsReqular },
    { src: poppinsBold, fontWeight: "bold" },
    { src: poppinsBlack, fontWeight: "black" },
    { src: poppinsItalic, fontStyle: "italic" },
  ],
  src: "../assets/fonts/Poppins",
});
const styles = StyleSheet.create({
  cardGrid: {
    //@ts-ignore
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    gridAutoRows: "max-content",
  },
  table: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  row: {
    display: "flex",
    flexDirection: "row",

    paddingTop: 8,
    paddingBottom: 8,
  },
  headerContainer: {
    display: "flex",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "none",
    height: "80px",
    width: "100%",
  },
  headerUnderline: {
    width: "100%",
    display: "flex",
    height: "1px",
    borderBottom: "1px solid #25252B",
    opacity: 0.1,
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "10px",
    alignItems: "center",
    width: "70px",
  },
  headerTitle: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#25252C",
  },
  generalTitle: {
    fontFamily: "Poppins",
    opacity: 0.5,
    fontSize: "10px",
    textAlign: "center",
    marginBottom: "5px",
  },
  sequenceName: {
    fontFamily: "Poppins",
    fontSize: "16px",

    fontWeight: "black",
  },
  created: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "10px",
    alignItems: "center",
    fontSize: "8px",
    color: "#25252C",
    opacity: 0.5,
  },

  index: {
    display: "flex",
    minWidth: "15px",
    minHeight: "15px",
    width: "15px",
    height: "15px",

    paddingTop: "3px",
    marginLeft: "5px",
    marginTop: "5px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "999999px",
    backgroundColor: "#F6D39D",
    fontSize: "8px",
    zIndex: 50,
  },

  cardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  image: {
    maxHeight: "60px",
    maxWidth: "60px",
  },

  nameContainer: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "35px",
    // backgroundColor: "#F5F5F6",
    textOverflow: "ellipsis",
    //@ts-ignore
    whiteSpace: "nowrap",
  },
  cardName: {
    fontFamily: "Poppins",
    maxWidth: "120px",
    //@ts-ignore
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: "7px",
  },
  sanskrit: {
    fontFamily: "Poppins",
    maxWidth: "120px",
    //@ts-ignore
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontStyle: "italic",
    fontSize: "6px",
  },

  row1: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "15%",
    marginLeft: "5px",
    marginRight: "5px",
    height: "130px",
    borderRadius: "10px",

    zIndex: 10,
  },
  footer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  footerText: {
    fontFamily: "Poppins",
    fontSize: "12",
    fontWeight: "black",
    color: "#25252C",
    opacity: 0.5,
  },

  row2: {
    width: "20%",
  },
  row3: {
    width: "20%",
  },
  row4: {
    width: "20%",
  },
  row5: {
    width: "20%",
  },
});

export function PDFSequence({
  customRef,
  classNames,
  cardSequence,

  sequenceName = "My sequence",
  ...rest
}: PDFDocumentProps) {
  let cardsArray = [];

  for (let i = 0; i < cardSequence.length; i++) {
    cardsArray[i] = {
      id: i + 1,
      cardImagePng: cardSequence[i].cardImagePng,
      name: cardSequence[i].name,
      sanskrit: cardSequence[i].sanskrit,
    };
  }
  return (
    <Document>
      <Page size="A4" style={{ height: "100%" }} wrap>
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <View style={styles.logo}>
              <Image src={logo} />
            </View>
            <View style={styles.headerTitle}>
              <Text style={styles.generalTitle}>Sequence Builder</Text>
              <Text style={styles.sequenceName}>
                {sequenceName && sequenceName.length > 21
                  ? `${sequenceName?.substring(0, 19)}...`
                  : sequenceName}
              </Text>
            </View>
            <View style={styles.created}>
              <Text>{`Created: ${format(
                new Date(),
                "dd.MM.yyyy"
              ).toString()}`}</Text>
            </View>
          </View>
          <View style={styles.headerUnderline} />
        </View>

        <View style={styles.table}>
          {chunk(cardsArray, 6).map((cards, index) => {
            return cards !== null ? (
              <View key={index} style={styles.row} wrap={false}>
                {cards.map((card, i) => {
                  console.log("CARD", card);
                  return (
                    <View key={i} style={styles.row1}>
                      <Text style={styles.index}>{card.id}</Text>
                      <View style={styles.cardImageContainer}>
                        <Image
                          style={styles.image}
                          src={
                            card.cardImagePng +
                            "?noCache=" +
                            Math.random().toString()
                          }
                        />
                      </View>

                      <View style={styles.nameContainer}>
                        <Text style={styles.cardName}>{card.name}</Text>
                        <Text style={styles.sanskrit}>{card.sanskrit}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            ) : null;
          })}
        </View>

        {/* <Text
          style={{ textAlign: "center" }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
        <View style={styles.footer} fixed>
          <Text style={{ ...styles.footerText }}>Made with</Text>
          <Image
            src={heart}
            style={{
              marginRight: "3px",
              marginLeft: "3px",
              height: "10px",
              width: "10px",
            }}
          />
          <Text style={{ ...styles.footerText }}>on tintyoga.com</Text>
        </View>
      </Page>
    </Document>
  );
}

export default PDFSequence;
