import classnames from "classnames";
import React from "react";
import { IPose } from "types";

interface AsanaCardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };
  isDraggable?: boolean;
  cardData: IPose;
  index: number;
  duplicateCard?: () => void;
  removeCard?: () => void;
  inputType?: string;
  componentKey?: string;
  placeholder?: string;
  isPicker?: boolean;
  boxesPerRow?: number;
}

export function AsanaPdfCard({
  customRef,
  classNames,
  cardData,

  componentKey,

  index,
  ...rest
}: AsanaCardProps) {
  return (
    <div className="relative">
      <div
        className={classnames(
          "relative flex flex-col justify-between items-center h-[28.25rem] rounded-[20px] overflow-hidden   pt-[1.188rem] border border-transparent cursor-pointer ",

          classNames?.container
        )}
      >
        <img
          className={classnames(
            "flex max-h-[9rem] max-w-[9rem] md:max-h-[10.863rem]  md:max-w-[11.711rem] pointer-events-none"
          )}
          alt="card"
          src={cardData.cardImage}
        />

        <div
          className={classnames(
            "flex flex-col  w-full justify-center items-center bg-cardTitleBg  px-2  "
          )}
        >
          <p className="font-bold text-xs h-full md:text-lg overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[85%]">
            {cardData.name}
          </p>
          <p className=" italic font-normal h-full text-xs overflow-hidden overflow-ellipsis whitespace-nowrap md:text-base max-w-[85%]">
            {cardData.sanskrit}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AsanaPdfCard;
