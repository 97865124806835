import ReactDOM from "react-dom";
import { ReactNode, useEffect } from "react";
import classnames from "classnames";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  headerChildren?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  portalNode?: HTMLElement;
  className?: string;
  isShowing?: boolean;
  showTitle?: boolean;
  showHeader?: boolean;
  onHide?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  classNames?: {
    mainContainer?: string;
    subMainContainer?: string;

    titleContainer?: String;
    title?: string;
    subTitle?: string;

    modalHeader?: string;
    backgroundImage?: string;
    closeButtonContainer?: string;
    closeButton?: string;
    borderLine?: string;
    childrenContainer?: string;
    searchChildrenContainer?: string;
    headerChildrenStyle?: string;
  };
  customMaxWidth?: boolean;
}
function ActionModal({
  isShowing = false,
  showHeader = true,

  onHide,
  children,
  className,
  subTitle,
  showTitle = true,
  classNames,
  headerChildren,
  portalNode = document.body,
  title = "Title",
  customMaxWidth,
}: IProps) {
  useEffect(() => {
    if (isShowing) {
      document.getElementById("html")?.classList.add("lock-screen");
    } else {
      document.getElementById("html")?.classList.remove("lock-screen");
    }
  }, [isShowing]);
  return (
    <>
      {isShowing &&
        ReactDOM.createPortal(
          <div
            className={classnames(
              "z-50 top-0 absolute left-0 w-full h-full font-poppins  outline-none flex justify-center items-center overflow-y-hidden",
              className
            )}
            tabIndex={-1}
            role="dialog"
          >
            <div className="w-full h-full opacity-50 bg-darkBlue   absolute z-40" />
            <div
              className={classnames(
                classNames?.mainContainer,
                "overflow-y-auto h-full p-6 sm:p-10  w-full flex mt-40 sm:mt-0 items-start sm:items-center sm:justify-center"
              )}
            >
              <div
                className={classnames(
                  classNames?.subMainContainer,
                  "flex flex-col w-full max-w-[850px] bg-white opacity-100 z-40  rounded-[10px] overflow-hidden "
                )}
              >
                {showHeader &&
                  (headerChildren ? (
                    <div
                      className={classnames(classNames?.headerChildrenStyle)}
                    >
                      {headerChildren}
                    </div>
                  ) : (
                    <div className="flex flex-col w-full">
                      <div
                        className={classnames(
                          "flex justify-between w-full relative bg-cardTitleBg  h-[5.3rem] p-6 ",
                          classNames?.modalHeader
                        )}
                      >
                        {showTitle && (
                          <div
                            className={classnames(
                              classNames?.titleContainer,
                              " flex flex-col items-center justify-center "
                            )}
                          >
                            <p
                              className={classnames(
                                classNames?.title,
                                "   text-darkBlue font-black text-xl"
                              )}
                            >
                              {title}
                            </p>
                            {subTitle && (
                              <p
                                className={classnames(
                                  classNames?.subTitle,
                                  " font-bold text-darkBlue05 mt-[10px] text-base "
                                )}
                              >
                                {subTitle}
                              </p>
                            )}
                          </div>
                        )}
                        <div
                          className={classnames(
                            classNames?.closeButtonContainer,
                            " flex justify-center items-center text-alert opacity-40 hover:opacity-100"
                          )}
                        >
                          {onHide && (
                            <FontAwesomeIcon
                              onClick={onHide}
                              className="fa-2x  cursor-pointer"
                              icon={faTimesCircle}
                            />
                          )}
                        </div>
                      </div>
                      <span
                        className={classnames(
                          classNames?.borderLine,
                          "border-darkBlue01 border-b   w-full"
                        )}
                      />
                    </div>
                  ))}

                <div className={classnames(classNames?.childrenContainer)}>
                  {children}
                </div>
              </div>
            </div>
          </div>,
          portalNode
        )}
    </>
  );
}

export default ActionModal;
