import { atom, useSetRecoilState, useRecoilValue } from "recoil";

import { STORE } from "const";
import { IPose } from "types";

export const searchPosesResultState = atom<IPose[]>({
  key: STORE.SEARCH_POSES_RESULT,
  default: [],
});

export function useSetSearchPosesResultDataHandler() {
  return useSetRecoilState(searchPosesResultState);
}

export function useGetSearchPosesResultData() {
  return useRecoilValue(searchPosesResultState);
}
