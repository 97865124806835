import { APP_ROUTES } from "const";
import { MobileSplash, CardPicker, NotFound, SequenceBuilder } from "modules";
import { lazy } from "react";
import { ICustomRouteObject } from "types";

const MainLayout = lazy(
  () => import("../layouts/MainLayout/MainLayout.component")
);
const BlankLayout = lazy(
  () => import("../layouts/BlankLayout/BlankLayout.component")
);

interface IRouteAccess {
  children: any;
  redirectTo: string;
}

function CommonAccess({ children, redirectTo }: IRouteAccess) {
  return children;
}

let routes: ICustomRouteObject[] = [
  {
    element: (
      <CommonAccess redirectTo={APP_ROUTES.NOTFOUND}>
        <MainLayout />
      </CommonAccess>
    ),
    children: [
      { index: true, element: <SequenceBuilder /> },
      {
        path: APP_ROUTES.SEQUENCE_BUILDER,
        element: <SequenceBuilder />,
      },
      {
        path: APP_ROUTES.CARD_BUILDER,
        element: <CardPicker />,
      },
    ],
  },
  {
    element: (
      <CommonAccess redirectTo={APP_ROUTES.SEQUENCE_BUILDER}>
        <BlankLayout />
      </CommonAccess>
    ),
    children: [
      { index: true, element: <NotFound /> },
      {
        path: APP_ROUTES.MOBILE_SPLASH,
        element: <MobileSplash />,
      },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export default routes;
