import { atom, useSetRecoilState, useRecoilValue } from "recoil";
import { persistAtom } from "./persist";

import { STORE } from "const";
import { ISequence } from "types";

export const sequenceState = atom<ISequence>({
  key: STORE.MISCELLANEOUS,
  default: { sequence: [], sequenceName: "" },
  effects_UNSTABLE: [persistAtom],
});

export function useSequenceDataHandler() {
  return useSetRecoilState(sequenceState);
}

export function useGetSequenceData() {
  return useRecoilValue(sequenceState);
}
