import { axios } from "services";
import { ENDPOINTS } from "const";

import { CancelTokenSource } from "axios";
import { IPose, IPoseWithPagination } from "types";

interface IProps {
  pageParam: any;
  cursor: number;
  skip: number;
  filterArray: Array<string>;
  search?: string | undefined | null;
  source?: CancelTokenSource;
}

export function getPosesList({
  pageParam,
  cursor,
  skip,
  filterArray,
  source,
}: IProps) {
  const promise = axios.get<IPoseWithPagination>(`${ENDPOINTS.POSES}`, {
    cancelToken: source?.token,
    params: { limit: pageParam, cursor, skip, filterArray },
  });

  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export function searchPoses({
  pageParam,
  cursor,
  skip,
  search,
  filterArray,
  source,
}: IProps) {
  const promise = axios.get<IPoseWithPagination>(`${ENDPOINTS.SEARCH}`, {
    cancelToken: source?.token,
    params: {
      limit: pageParam,
      cursor,
      skip,
      searchString: search,
      filterArray,
    },
  });

  //@ts-ignore
  promise.cancel = () => {
    source?.cancel("Query was cancelled by React Query");
  };

  return promise;
}

export function createUser(token?: string) {
  return axios.post<IPose>(ENDPOINTS.POSES, {
    Headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
