import classnames from "classnames";
import { ActionToolbarFilterPopup, Loader } from "components";
import { useOnClickOutside, useReturnCallbackWithStateChanger } from "hooks";

import React, { useRef, useState } from "react";

interface ToolbarFilterButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  customRef?: React.MutableRefObject<HTMLButtonElement | null>;
  applyActionPanelStyle?: boolean;
  formStatus?: any;
  classNames?: {
    className?: string;
    mainContainer: string;
    buttonText?: string;
    button?: string;
    popUp?: string;
  };
  popupContent?: JSX.Element;
  popupPosition?: "left" | "right" | "center";
  popupType?: "regular" | "share";
  tooltipText?: string;
  isOverrideType?: boolean;
  option?: string;
  buttonType?: string;
  disable?: boolean;
  onClick?: () => void;
}

export function ToolbarFilterButton({
  option,
  buttonType,
  customRef,
  tooltipText,
  formStatus,
  classNames,
  popupContent,
  popupType,
  disable = false,
  popupPosition,
  className,
  onClick,
  ...rest
}: ToolbarFilterButtonProps) {
  const [divHovered, setDivHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const setShow = useReturnCallbackWithStateChanger(setShowPopup);
  const showPopupRef = useRef<HTMLDivElement | null>(null);
  const showPopupRefButton = useRef<HTMLButtonElement | null>(null);
  useOnClickOutside(showPopupRef, showPopupRefButton, () =>
    setShowPopup(false)
  );

  return (
    <div
      className={classnames("relative group w-full flex flex-row", className)}
    >
      <div
        ref={showPopupRef}
        onMouseOver={() => {
          setDivHovered(true);
        }}
        onMouseOut={() => {
          setDivHovered(false);
        }}
        className={classnames(
          "flex flex-row relative overflow-hidden rounded-[100px] cursor-pointer  border border-dropdownButtonToolbarBorder group-hover:border-gold  ",
          classNames?.mainContainer
        )}
        onClick={onClick || setShow}
      >
        <button
          ref={customRef || showPopupRefButton}
          {...rest}
          disabled={disable}
          className={classnames(
            " py-2.5 pl-4 pr-[1.688rem] flex items-center  justify-center  bg-white hover:bg-dropdownButtonToolbarBgHovered   font-normal transition-all transform duration-[250ms]  ease-linear    ",
            {
              "pointer-events-none opacity-50 border-transparent ": disable,
              "  ": !disable,
              " border-dropdownButtonToolbarBorderHovered bg-dropdownButtonToolbarBgHovered":
                divHovered,
            },
            classNames?.button
          )}
        >
          {!formStatus?.loading === true && option && (
            <p
              className={classnames(
                classNames?.buttonText,
                " mt-[2px]  text-darkBlue text-base whitespace-nowrap ml-[15px]"
              )}
            >
              {option}
            </p>
          )}

          {formStatus?.loading === true && (
            <div className="flex justify-center">
              <Loader />
            </div>
          )}
        </button>
        <div
          className={classnames(
            "flex bg-[#F5F5F6] w-12 items-center justify-center  ",
            {
              hidden: !rest.children,
            }
          )}
        >
          {rest.children ? rest.children : null}
        </div>
      </div>
      <div className=" pt-16">
        {popupContent && (
          <ActionToolbarFilterPopup
            position={popupPosition}
            popupContent={popupContent}
            showPopup={showPopup}
            customRef={showPopupRef}
          />
        )}
      </div>
    </div>
  );
}

export default ToolbarFilterButton;
