import classnames from "classnames";

import skyline from "../../assets/images/skyline.png";

interface IFooter {
  className?: string;
}

function Footer({ className }: IFooter) {
  return (
    <div
      id="footer"
      className={classnames(
        className,
        "flex flex-col md:flex-row justify-between items-center w-full maxMd:min-h-[8.5rem] max-h-[8.5rem] md:max-h-[5rem] bg-white border-t border-borderColor overflow-hidden px-2 sm:px-6  md:px-12 py-7"
      )}
    >
      <div className="hidden md:flex order-2  md:order-1 flex-row items-center">
        <div className=" h-[50px]  w-[50px]   rounded-full border border-footerIconBorder border-opacity-10 overflow-hidden object-contain">
          <img alt="skyline" src={skyline} />
        </div>
        <p className="font-poppins tracking-normal whitespace-nowrap text-darkBlue03 text-lg  hover:opacity-100 ml-7">
          Born in Frankfurt
        </p>
      </div>
      <div className=" order-1 md:order-2">
        <p className="font-bold text-black text-lg md:text-lg whitespace-nowrap">
          ALL YOU NEED IS INSIDE | WE MOVE AS ONE.
        </p>
      </div>
      <div className="hidden order-3 md:flex flex-row">
        <a
          className="font-poppins tracking-normal leading-[39px] whitespace-nowrap hover:text-darkBlue text-darkBlue03 text-lg  hover:opacity-100"
          href="https://tintyoga.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Data Privacy
        </a>
        <a
          className="font-poppins tracking-normal leading-[39px] whitespace-nowrap hover:text-darkBlue text-darkBlue03 text-lg  hover:opacity-100 ml-[20px]"
          href="https://tintyoga.com/terms-of-use/"
          target="_blank"
          rel="noreferrer"
        >
          Legal Notice
        </a>
      </div>
      <div className="order-3 flex md:hidden w-full flex-col  xs:flex-row justify-between">
        <div className="flex md:hidden order-1  md:order-1 flex-row justify-center xs:justify-start items-center xs:pl-4 pt-[10px] xs:pt-0">
          <div className="h-[29px] w-[29px] md:h-[50px] md:w-[50px]  rounded-full border border-footerIconBorder border-opacity-10 overflow-hidden object-contain">
            <img alt="skyline" src={skyline} />
          </div>
          <p className="font-poppins tracking-normal whitespace-nowrap text-darkBlue text-xs sm:text-sm   hover:opacity-100 ml-2 sm:ml-4">
            Born in Frankfurt
          </p>
        </div>
        <div className="ml-4 flex   order-2 items-center justify-center pr-4">
          <a
            className="font-poppins tracking-normal leading-[39px] whitespace-nowrap hover:text-darkBlue text-darkBlue03 text-xs sm:text-sm pt-0.5  hover:opacity-100"
            href="https://tintyoga.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Data Privacy
          </a>
          <a
            className="font-poppins tracking-normal leading-[39px] whitespace-nowrap hover:text-darkBlue text-darkBlue03 text-xs sm:text-sm pt-0.5  hover:opacity-100 ml-2"
            href="https://tintyoga.com/terms-of-use/"
            target="_blank"
            rel="noreferrer"
          >
            Legal Notice
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
