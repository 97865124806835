import { useCallback, useState } from "react";

export function useDebounce(delay: number, func?: (...params: any) => any) {
  const [id, setId] = useState<NodeJS.Timeout | null>();
  return useCallback(
    (...args: any) => {
      if (id) {
        clearTimeout(id);
      }
      const newId = setTimeout(() => {
        setId(null);
        if (func) {
          func(...args);
        }
      }, delay);
      setId(newId);
    },
    [func, id, setId, delay]
  );
}
