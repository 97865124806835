import { Loader, ErrorFallback } from "components";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { useRoutes } from "react-router-dom";
import routes from "./router/router.config";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5N6PS9T",
};

TagManager.initialize(tagManagerArgs);

const client = new QueryClient();
function App() {
  let element = useRoutes(routes);
  return (
    <RecoilRoot>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={client}>
          <Suspense fallback={<Loader />}>{element}</Suspense>
        </QueryClientProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
}

export default App;
