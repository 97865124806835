import { atom, useSetRecoilState, useRecoilValue } from "recoil";

import { STORE } from "const";

export const filterState = atom<Array<string>>({
  key: STORE.FILTER,
  default: [],
});

export function useSetFilterDataHandler() {
  return useSetRecoilState(filterState);
}

export function useGetFilterData() {
  return useRecoilValue(filterState);
}
