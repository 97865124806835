import { APP_ROUTES } from "const";
import { ReactNode } from "react";

interface IError {
  error?: any;
  children?: ReactNode;
}

function ErrorFallback({ error, children }: IError) {
  return (
    <div role="alert" className="w-full text-3xl text-center mt-[200px] ">
      <>
        {error.response?.status !== 401 && (
          <div role="alert" className="w-full text-3xl text-center mt-[200px] ">
            <p className="font-bold text-4xl">Something went wrong:</p>
            <pre className="mt-5 text-red-400">
              {!children && `Error: ${error.message}`}
              {children}
            </pre>
            <div className="mt-10">
              <a
                className="p-4 h-[20px] w-[150px] border bg-[#00649C] text-white"
                href={APP_ROUTES.CARD_BUILDER}
              >
                Return to main page
              </a>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
export default ErrorFallback;
