import { Routes } from "react-router";
import { IRoute } from "types";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

interface IProps {
  routes: IRoute[];
}

export function CustomRouter({ routes }: IProps) {
  return (
    <Routes>
      {routes.map((route: IRoute) => (
        <RouteWithSubRoutes authenticated={true} {...route} />
      ))}
    </Routes>
  );
}
export default CustomRouter;
