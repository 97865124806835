import classnames from "classnames";
import { Loader } from "components";
import { twMerge } from "tailwind-merge";

import React from "react";

interface PrimaryButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  customRef?: React.MutableRefObject<HTMLButtonElement | null>;
  applyActionPanelStyle?: boolean;
  formStatus?: any;
  classNames?: {
    className?: string;
    mainBg?: string;
    buttonText?: string;
  };
  fontType?: "bold" | "normal" | "black";
  isOverrideType?: boolean;
  option: string;
  buttonType?: string;
  disable?: boolean;
  onClick?: () => void;
}

export function PrimaryButton({
  option,
  buttonType,
  customRef,
  fontType = "black",
  formStatus,
  classNames,
  disable = false,
  onClick,
  ...rest
}: PrimaryButtonProps) {
  return (
    <button
      ref={customRef}
      {...rest}
      disabled={disable}
      onClick={onClick}
      className={twMerge(
        classnames(
          " bg-[#2e6164] rounded-[100px] flex justify-center items-center  ",
          {
            "font-black": fontType === "black",
            "font-normal": fontType === "normal",
            "font-bold": fontType === "bold",
          },
          {
            "text-alert text-base ": buttonType === "delete",
            "text-deny hover:text-white ": buttonType === "deny",
            "text-darkBlue": buttonType === "approve",
          },
          classNames?.mainBg,
          {
            "px-10 py-3 ": buttonType !== "delete",
            "px-7 py-3": buttonType === "delete",
          },
          {
            "  border border-transparent hover:border-darkBlue":
              buttonType === "approve",
            " bg-lightGreen ": buttonType === "approve" && !classNames?.mainBg,
            " bg-denyBg hover:bg-primaryButtonFillColor":
              buttonType === "deny" && !classNames?.mainBg,
            " bg-deleteBg border border-deleteBorder":
              buttonType === "delete" && !classNames?.mainBg,
            "   border border-primaryButtonFillColor ": buttonType === "deny",
            "pointer-events-none opacity-50 border-transparent ": disable,
            "   transition-all duration-300 ease-in-out": !disable,
          },
          rest.className
        )
      )}
    >
      <div
        className={classnames("mr-4", {
          hidden: !rest.children,
        })}
      >
        {rest.children ? rest.children : null}
      </div>

      {!formStatus?.loading === true && option && (
        <p
          className={classnames(
            classNames?.buttonText,
            "font-poppins      whitespace-nowrap text-white",
            {
              "text-lg": buttonType !== "delete",
            }
          )}
        >
          {option}
        </p>
      )}
      {formStatus?.loading === true && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </button>
  );
}

export default PrimaryButton;
