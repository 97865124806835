import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faLightbulbOn,
  faPlayCircle,
  faQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import classnames from "classnames";
import { ActionModal, MenuRoundButton, PrimaryButton } from "components";

import React, { useState } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

interface MenuProps extends React.HTMLAttributes<HTMLElement> {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    buttonText?: string;
  };
  openMenu?: boolean;
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?: () => void;
}

function MenuItemExternalLink({ href, text }: { href: string; text: string }) {
  return (
    <a
      className="font-poppins tracking-normal leading-[39px]  text-center font-semibold text-lg hover:text-gray-300"
      href={href}
      rel="noreferrer"
    >
      {text}
    </a>
  );
}

export function Menu({
  customRef,
  classNames,
  openMenu,
  setOpenMenu,
  ...rest
}: MenuProps) {
  const [, setIsMouseOverFirst] = useState(false);
  const [, setIsMouseOverSecond] = useState(false);
  const [, setIsMouseOverThird] = useState(false);
  const [, setIsMouseOverFourth] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  return (
    <div
      ref={customRef}
      {...rest}
      onClick={() => {
        if (setOpenMenu) {
          setOpenMenu(!openMenu);
        }
      }}
      className={classnames(
        "  flex flex-col  justify-center bg-white w-full h-full text-left  top-[72px] lg:top-[80px]  border-t  border-gainsboro left-0 transition-all duration-300 ease-in-out z-50 ",
        {
          " translate-x-0 fixed ": openMenu,
          " translate-x-full fixed ": !openMenu,
        },

        rest.className
      )}
    >
      <div className="w-full h-full flex flex-col">
        <div className="grid grid-flow-row items-center justify-center gap-[36px] mt-[44px] mb-[44px] sm:mt-[88px] sm:mb-[88px] px-8">
          <MenuItemExternalLink
            href="https://insideyoga.org/"
            text="Back to home insideyoga.org"
          />
          <MenuItemExternalLink
            href="https://insideyoga.org/inside-online/"
            text="Inside Online"
          />
          <MenuItemExternalLink
            href="https://tintyoga.com/academy/"
            text="Online Trainings"
          />

          <PrimaryButton
            fontType="normal"
            onClick={() => {
              document.location.href = "https://insideyoga.org/get-inside";
            }}
            //buttonType="deny"
            option="Get Inside"
            className=" border-none  "
            classNames={{
              buttonText: " font-semibold",
            }}
          />
        </div>
        <span className="block minHeaderSize:hidden w-full h-[1px] border-b border-gainsboro opacity-100" />
        <div className="grid grid-flow-col justify-center  grid-cols-menu-buttons gap-[20px] mt-12 sm:mt-24  z-60 px-8 pb-8">
          <MenuRoundButton
            isOnTheToolbar={false}
            onMouseOut={() => {
              setIsMouseOverFirst(false);
            }}
            onMouseOver={() => {
              setIsMouseOverFirst(true);
            }}
            popupPosition="left"
            popupType="share"
            popupContent={
              <div className="grid grid-flow-col gap-1 p-4">
                <FacebookShareButton
                  quote="I found something awesome, take a look here"
                  title="I found something awesome, take a look here"
                  url="https://tools.tintyoga.com/sequence-builder"
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  title="I found something awesome, take a look here"
                  url="https://tools.tintyoga.com/sequence-builder"
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <TelegramShareButton
                  title="I found something awesome, take a look here"
                  url="https://tools.tintyoga.com/sequence-builder"
                >
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>

                <WhatsappShareButton
                  title="I found something awesome, take a look here "
                  url="https://tools.tintyoga.com/sequence-builder"
                >
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </div>
            }
            tooltipText="Share"
          >
            <FontAwesomeIcon
              className="fa-lg text-nero hover:text-gold group-hover:text-gold"
              icon={faShareAlt}
            />
          </MenuRoundButton>
          <MenuRoundButton
            isOnTheToolbar={false}
            onMouseOut={() => {
              setIsMouseOverSecond(false);
            }}
            onMouseOver={() => {
              setIsMouseOverSecond(true);
            }}
            onClick={() => {
              setShowTutorialModal((val) => !val);
            }}
            tooltipText="Watch Tutorial"
          >
            <FontAwesomeIcon
              className="fa-lg text-nero hover:text-gold group-hover:text-gold"
              icon={faPlayCircle}
            />
          </MenuRoundButton>
          <MenuRoundButton
            isOnTheToolbar={false}
            onMouseOut={() => {
              setIsMouseOverThird(false);
            }}
            onMouseOver={() => {
              setIsMouseOverThird(true);
            }}
            onClick={() => {
              document.location.href =
                "https://tintyoga.com/sequence-builder-feedback/";
            }}
            tooltipText="Feedback and Ideas"
          >
            <FontAwesomeIcon
              className="fa-lg text-nero hover:text-gold group-hover:text-gold"
              icon={faLightbulbOn}
            />
          </MenuRoundButton>
          <MenuRoundButton
            isOnTheToolbar={false}
            popupType="regular"
            popupPosition="right"
            popupDirection="horizontal"
            popupContent={
              <div className="flex flex-row rounded-[10px] ">
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-tl-[10px] rounded-bl-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-3 py-3 w-full flex-row")}
                    onClick={() => {
                      document.location.href = "https://tintyoga.com/contact/";
                    }}
                  >
                    <p className="text-darkBlue">Support</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-b border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm   text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-3 py-3 w-full flex-row")}
                    onClick={() => {
                      document.location.href =
                        "https://help.tintyoga.com/portal/en/kb/sequence-builder";
                    }}
                  >
                    <p className="text-darkBlue">Help-Center</p>
                  </button>
                </div>
                <div
                  className={classnames(
                    "flex flex-1 z-20  flex-col bg-white border-none border-inputBorder hover:bg-darkBlue005  justify-start items-start  font-bold text-sm  rounded-tr-[10px] rounded-br-[10px] text-darkBlue05"
                  )}
                >
                  <button
                    className={classnames("flex px-3 py-3 w-full flex-row")}
                    onClick={() => {
                      setShowTutorialModal((val) => !val);
                    }}
                  >
                    <p className="text-darkBlue">How to use</p>
                  </button>
                </div>
              </div>
            }
            onMouseOut={() => {
              setIsMouseOverFourth(false);
            }}
            onMouseOver={() => {
              setIsMouseOverFourth(true);
            }}
            tooltipText="Help"
          >
            <FontAwesomeIcon
              className="fa-lg text-nero hover:text-gold group-hover:text-gold"
              icon={faQuestion}
            />
          </MenuRoundButton>
        </div>
      </div>
      <ActionModal
        isShowing={showTutorialModal}
        title="Tutorial"
        customMaxWidth
        className="backdrop-filter  "
        onHide={() => {
          setShowTutorialModal(false);
        }}
        classNames={{
          subMainContainer: "  ",
          modalHeader: " ",
          childrenContainer: "   ",
          borderLine: "   ",
          closeButtonContainer: " ",
          closeButton: "",
          titleContainer: " ",
          title: "",
          subTitle: "",
        }}
      >
        <div className="flex flex-col flex-1 w-full h-full">
          <div className="flex p-6 justify-end items-center min-h-[350px] sm:min-h-[550px] w-full h-full aspect-w-16  flex-row  ">
            <div style={{ padding: "57% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/675809102?h=b3d9518a18&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Sequence Builder Tutorial.mov"
              ></iframe>
            </div>
          </div>
        </div>
      </ActionModal>
    </div>
  );
}

export default Menu;
